<template>
  <div class="relative" @click="handleBulkDropdown">
    <template v-if="externalPreview">
      <!-- Show the same button with top 2 labels and a dropdown just displaying the rest of labels-->
      <button
        class="text-[#757A8A] align-items-center !bg-[#F9F9F9] inline-flex gap-[0.5rem] text-[0.875rem] leading-4 !border border-solid font-weight-400 py-[0.67rem] px-[1rem] !border-[#D2D5DF] rounded-[0.3rem]"
        :class="{
          '!py-[0.295rem] ': externalLabels.length,
          'mr-[0.5rem]': mode === 'planner',
        }"
        @click="showExternalDropdown = !showExternalDropdown"
      >
        <img
          v-tooltip="'Labels'"
          src="@src/assets/img/chat_bot/labels.svg"
          alt="label icon"
          class="h-[1rem] w-[1rem]"
        />
        <template v-if="externalLabels.length">
          <!--Show the Attached Labels -->
          <span
            v-for="(label, index) in externalLabels.slice(0, 2)"
            :key="index"
            v-tooltip="{
              content: label.name.length > 22 ? label?.name : '',
            }"
            class="px-3 py-1 rounded-md text-sm flex items-center gap-1 text-nowrap"
            :style="getLabelColor(label.color)"
          >
            {{
              label.name.length > 22
                ? label.name.substring(0, 22) + '...'
                : label.name
            }}
          </span>
          <span
            v-if="externalLabels.length > 2"
            class="rounded-full bg-[#FFC555] text-center text-[#333333] w-6 h-6 flex items-center justify-center"
          >
            {{ externalLabels.length - 2 }}+
          </span>
        </template>
        <span v-else>Label</span>
      </button>

      <!--  Show external labels in dropdown-->
      <div
        v-if="showExternalDropdown"
        class="absolute top-full right-0 mt-1 z-10"
      >
        <div
          class="w-80 bg-white rounded-xl shadow-md border border-gray-200 overflow-hidden transition duration-300 ease-in-out"
        >
          <!-- ONly show the labels -->
          <div class="!max-h-96 overflow-y-auto my-2">
            <div v-if="!externalLabels.length" class="my-4">
              <p class="text-center text-sm text-[#757A8A] font-normal"
                >No labels Attached.</p
              >
            </div>

            <div
              v-for="(label, index) in externalLabels"
              :key="index"
              class="flex flex-col px-4 py-2 group hover:bg-blue-50/30"
            >
              <div class="flex justify-between items-center my-1">
                <label class="flex items-center cursor-pointer !mb-0">
                  <span
                    v-tooltip="{
                      content: label.name.length > 22 ? label?.name : '',
                    }"
                    class="ml-2 px-3 py-1 rounded-md text-sm text-nowrap"
                    :style="getLabelColor(label.color)"
                  >
                    {{
                      label.name.length > 22
                        ? label.name.substring(0, 22) + '...'
                        : label.name
                    }}
                  </span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template v-else>
      <span v-if="mode === 'bulk-move'" class="w-full"> Label </span>
      <template v-else>
        <img
          v-if="mode === 'planner'"
          class="absolute top-0 -left-6"
          src="@src/assets/img/common/new_tag.svg"
          alt="new-tag"
        />
        <button
          class="text-[#757A8A] align-items-center !bg-[#F9F9F9] inline-flex gap-[0.5rem] text-[0.875rem] leading-4 !border border-solid font-weight-400 py-[0.67rem] px-[1rem] !border-[#D2D5DF] rounded-[0.3rem]"
          :class="{
            '!py-[0.295rem] ': getAttachedLabels.length,
            'mr-[0.5rem]': mode === 'planner',
          }"
          @click="showLabelDropdown = !showLabelDropdown"
        >
          <img
            v-tooltip="'Labels'"
            src="@src/assets/img/chat_bot/labels.svg"
            alt="label icon"
            class="h-[1rem] w-[1rem]"
          />
          <template v-if="getAttachedLabels.length">
            <!--Show the Attached Labels -->
            <span
              v-for="(label, index) in getAttachedLabels.slice(
                0,
                isSmallScreen ? 1 : 2
              )"
              :key="index"
              v-tooltip="{
                content:
                  label.name.length > (isSmallScreen ? 12 : 22)
                    ? label?.name
                    : '',
              }"
              class="px-3 py-1 rounded-md text-sm flex items-center gap-1 text-nowrap"
              :style="getLabelColor(label.color)"
            >
              {{
                label.name.length > 24
                  ? label.name.substring(0, isSmallScreen ? 12 : 22) + '...'
                  : label.name
              }}
            </span>
            <span
              v-if="getAttachedLabels.length > (isSmallScreen ? 1 : 2)"
              class="rounded-full bg-[#FFC555] text-center text-[#333333] w-6 h-6 flex items-center justify-center"
            >
              {{ getAttachedLabels.length - (isSmallScreen ? 1 : 2) }}+
            </span>
          </template>
          <span v-else>Label</span>
        </button>
      </template>
      <div
        v-if="showLabelDropdown"
        v-click-away="handleClickAway"
        class="absolute top-full mt-1 z-10"
        :class="getDropdownPosition"
      >
        <div
          class="w-80 bg-white rounded-xl shadow-md border border-gray-200 overflow-hidden transition duration-300 ease-in-out"
          @click="preventClick"
        >
          <!-- Header -->
          <div
            class="flex justify-between items-center px-4 py-3 border border-b border-gray-100"
          >
            <!-- Add Label-->
            <div v-if="!isSearchActive" class="flex items-center gap-2">
              <span class="text-[#3A4557] font-medium text-base">Labels</span>
              <img
                v-if="!showAddLabel"
                v-tooltip="'Add a new Label'"
                src="@src/assets/img/common/add-circle.svg"
                class="h-5 w-5 cursor-pointer"
                alt="add label"
                @click.stop="showAddLabel = true"
              />
              <img
                v-else
                v-tooltip="'Add a new Label'"
                src="@src/assets/img/common/add-circle-disabled.svg"
                class="h-5 w-5 cursor-pointer"
                alt="add label disabled"
              />
            </div>

            <div class="flex items-center gap-x-1">
              <!-- Search -->
              <div
                class="flex items-center bg-gray-100/70 rounded-md px-3 py-1"
                :class="{ 'w-full': isSearchActive }"
              >
                <img
                  src="@src/assets/img/chat_bot/search-icon.svg"
                  class="h-4 w-4"
                  alt="search"
                />
                <input
                  v-model="searchQuery"
                  type="text"
                  class="bg-transparent w-20 ml-1 text-sm border-0 p-1 rounded"
                  :class="{ '!w-full': isSearchActive }"
                  placeholder="Search"
                  @focus="isSearchActive = true"
                />
                <img
                  v-show="isSearchActive"
                  src="@src/assets/img/common/circle-xmark.svg"
                  alt="xmark"
                  class="h-4 w-4 cursor-pointer"
                  @click.stop="resetSearch"
                />
              </div>

              <!-- Settings -->
              <div
                v-tooltip="'Manage and edit your labels here.'"
                class="cursor-pointer font-normal text-gray-900 hover:text-primary-variant-1 transition-colors"
                @click="handleNavigation"
              >
                <i
                  class="icon-settings-cs h-9 w-9 text-xl text-center pt-1"
                ></i>
              </div>
            </div>
          </div>

          <!-- Add Label-->
          <div
            v-if="showAddLabel"
            class="px-4 py-2 border border-b border-gray-100"
          >
            <div class="mb-3 relative">
              <input
                v-model="labelName"
                type="text"
                placeholder="Type label name"
                class="w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md shadow-sm"
              />
              <img
                src="@src/assets/img/chat_bot/labels.svg"
                alt="label icon"
                class="h-4 w-4 absolute left-3 top-1/2 transform -translate-y-1/2"
              />
            </div>

            <div class="flex items-center justify-between mb-2">
              <div class="flex gap-2 relative mt-1 ml-2">
                <p class="text-sm font-medium text-gray-900 mb-2">Color</p>
                <img
                  v-click-away="hideColorPicker"
                  src="@src/assets/img/common/color-picker.svg"
                  class="h-5 w-5 cursor-pointer rounded-md p-0.5 border border-gray-400"
                  :style="{
                    backgroundColor: hexToRGBA(
                      LABELS_COLOR_MAP[selectedColor],
                      0.8
                    ),
                  }"
                  alt="color picker"
                  @click.stop="showColorPicker = !showColorPicker"
                />
                <div
                  v-if="showColorPicker"
                  class="absolute left-0 top-full bg-white rounded-xl w-72 p-1 grid grid-cols-10 shadow-sm gap-1 border border-gray-200"
                  @click.stop
                >
                  <div
                    v-for="color in Object.values(LABELS_COLOR_MAP)"
                    :key="color"
                    :class="`w-6 h-6 rounded-md border border-gray-200 relative cursor-pointer`"
                    :style="`background-color: ${hexToRGBA(color, 0.8)}`"
                    @click="selectColor(color)"
                  >
                    <img
                      v-if="color === LABELS_COLOR_MAP[selectedColor]"
                      src="@src/assets/img/chat_bot/tick.svg"
                      alt="selected"
                      class="absolute inset-0 m-auto w-3 h-3"
                    />
                  </div>
                </div>
              </div>

              <div class="flex justify-end gap-2 mr-2">
                <CstButton
                  size="small"
                  variant="text"
                  @click.stop="resetStates"
                >
                  Cancel
                </CstButton>
                <CstButton
                  size="small"
                  :disabled="!labelName || !selectedColor"
                  variant="primary"
                  @click.stop="createLabel"
                >
                  {{ 'Create' }}
                </CstButton>
              </div>
            </div>
          </div>

          <!-- Labels -->
          <div class="!max-h-96 overflow-y-auto my-2">
            <div
              v-if="!LabelsExist"
              class="flex items-center justify-center my-4"
            >
              <p class="text-center text-sm text-[#757A8A] font-normal"
                >No labels yet. Click</p
              >
              <img
                src="@src/assets/img/common/add-circle.svg"
                class="h-5 w-5 cursor-pointer mx-1"
                alt="add label"
                @click.stop="showAddLabel = true"
              />
              <p class="text-center text-sm text-[#757A8A] font-normal"
                >to create one.</p
              >
            </div>
            <!-- No Data Found in search   -->
            <div
              v-if="!searchLabels.length"
              class="flex items-center justify-center my-4"
            >
              <p class="text-center text-sm text-[#757A8A] font-normal"
                >No labels found.</p
              >
            </div>
            <div
              v-for="(label, index) in searchLabels"
              :key="index"
              class="flex flex-col px-4 py-2 group hover:bg-blue-50/30"
            >
              <div class="flex justify-between items-center my-1">
                <label class="flex items-center cursor-pointer !mb-0">
                  <input
                    v-model="selectedLabels"
                    :value="label._id"
                    type="checkbox"
                    class="hidden"
                  />
                  <span class="checkbox-icon">
                    <span
                      v-if="!selectedLabels.includes(label._id)"
                      class="group"
                    >
                      <img
                        src="@src/assets/img/common/unchecked.svg"
                        alt="Unchecked"
                        class="w-5 h-5"
                      />
                    </span>
                    <img
                      v-else
                      src="@src/assets/img/common/checked.svg"
                      alt="Checked"
                      class="w-5 h-5"
                    />
                  </span>
                  <span
                    v-tooltip="{
                      content: label.name.length > 22 ? label?.name : '',
                    }"
                    class="ml-2 px-3 py-1 rounded-md text-sm text-nowrap"
                    :style="getLabelColor(label.color)"
                  >
                    {{
                      label.name.length > 22
                        ? label.name.substring(0, 22) + '...'
                        : label.name
                    }}
                  </span>
                </label>
              </div>
            </div>
          </div>

          <!-- Footer -->
          <div
            v-if="mode === 'bulk-move'"
            class="flex justify-end items-center px-4 py-3 gap-x-2 border border-t border-gray-100"
          >
            <CstButton size="small" variant="text" @click.stop="handleClose">
              Cancel
            </CstButton>
            <CstButton
              size="small"
              variant="primary"
              :disabled="isSame || isLoading"
              @click.stop="attachLabelToPlan"
            >
              {{ 'Save' }}
            </CstButton>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script setup>
// Core Imports
import {
  computed,
  defineEmits,
  defineProps,
  inject,
  onMounted,
  onUnmounted,
  ref,
} from 'vue'

// Other Imports
import CstButton from '@ui/Button/CstButton.vue'
import { useStore } from '@state/base'
import useLabelAndCampaign from '@src/modules/analytics/views/performance-report/label-and-campaign/composables/useLabelAndCampaign.js'
import { useRouter } from 'vue-router'
import { useComposerHelper } from '@modules/composer_v2/composables/useComposerHelper'
import { swalAttributes } from '@common/constants/common-attributes'
// Helpers
const emit = defineEmits([
  'bulk-action',
  'update-attached-labels',
  'update-labels',
  'minimize-composer',
])
const store = useStore()
const router = useRouter()
const root = inject('root')
const { $bvModal } = root
const {
  LABELS_COLOR_MAP,
  attachLabel,
  hexToRGBA,
  getLabelColor,
  bulkLabelPlans,
} = useLabelAndCampaign()

const { isDraftComposer } = useComposerHelper()

// Props
const props = defineProps({
  planLabels: {
    type: Array,
    default: () => [],
  },
  attachedLabels: {
    type: Array,
    default: () => [],
  },
  planId: {
    type: String,
    default: null,
  },
  mode: {
    type: String,
    default: 'planner',
  },
  externalPreview: {
    type: Boolean,
    default: false,
  },
  externalLabels: {
    type: Array,
    default: () => [],
  },
})

// Data
const showLabelDropdown = ref(false)
const showAddLabel = ref(false)
const selectedColor = ref('color_1')
const labelName = ref('')
const showColorPicker = ref(false)
const searchQuery = ref('')
const labels = ref(store.getters?.getLabels || [])
const selectedLabels = ref(props.attachedLabels || [])
const isSearchActive = ref(false)
const isSmallScreen = ref(false)
const isLoading = ref(false)
const showExternalDropdown = ref(false)

// Computed

// Get the labels attached to current plan
const getAttachedLabels = computed(() => {
  return labels.value?.filter((label) =>
    selectedLabels.value?.includes(label?._id)
  )
})

// Check if Labels exist
const LabelsExist = computed(() => {
  return labels.value?.length
})

// check if props labels and attached label's ids are same
const isSame = computed(() => {
  // Bulk move mode
  if (props.mode === 'bulk-move' && selectedLabels.value.length === 0) {
    return true
  }

  // Check if both arrays are defined and have the same length
  if (
    !props.planLabels ||
    !selectedLabels.value ||
    props.planLabels.length !== selectedLabels.value.length
  ) {
    return false
  }

  // Create a Set from one of the arrays for efficient lookup
  const labelSet = new Set(props.planLabels)

  // Check if all elements in selectedLabels are in the Set
  return selectedLabels.value?.every((id) => labelSet.has(id))
})

// Filter the labels based on search query
const searchLabels = computed(() => {
  return labels.value?.filter((label) =>
    label.name.toLowerCase().includes(searchQuery.value.toLowerCase())
  )
})

// Get Dropdown position
const getDropdownPosition = computed(() => {
  switch (props.mode) {
    case 'planner':
      return 'right-2'
    case 'bulk-move':
      // dropdown should open on the right side of the button
      return 'left-full -top-2'
    default:
      return 'right-0'
  }
})

// Small Screen
const checkSmallScreen = () => {
  return (isSmallScreen.value = window.innerWidth < 1530)
}

// Lifecycle Hooks
onMounted(() => {
  checkSmallScreen()
  window.addEventListener('resize', checkSmallScreen)
})

onUnmounted(() => {
  window.removeEventListener('resize', checkSmallScreen)
})

// Methods

const selectColor = (color) => {
  selectedColor.value = getObjectKey(color)
  showColorPicker.value = false
}

const resetStates = () => {
  showAddLabel.value = false
  labelName.value = ''
  showColorPicker.value = false
  selectedColor.value = 'color_1'
  searchQuery.value = ''
}

const getBulkLabelPlans = () => {
  if (props.mode === 'bulk-move') {
    emit('bulk-action', 'label')
  }
}
const preventClick = (e) => {
  if (props.mode === 'bulk-move') {
    e.stopPropagation()
  }
}

// Attach Label
const attachLabelToPlan = async () => {
  isLoading.value = true

  if (props.mode === 'planner' || props.mode === 'bulk-move') {
    const data = {
      ids: props.mode === 'bulk-move' ? bulkLabelPlans.value : [props.planId],
      label_ids: selectedLabels.value,
    }
    // Attach the label to the plan
    await attachLabel(data)

    // Update the attached labels by filtering the selected labels
    emit('update-attached-labels', selectedLabels.value)
    selectedLabels.value = props.attachedLabels || []
    showLabelDropdown.value = false
    isLoading.value = false
    return
  }
  if (props.mode === 'composer') {
    emit('update-labels', selectedLabels.value)
  }
  // Close the dropdown
  showLabelDropdown.value = false
  isLoading.value = false
}

// Save Labels upon closing the dropdown
const handleSubmit = () => {
  if (!isSame.value) {
    attachLabelToPlan()
  } else {
    handleClose()
  }
  showLabelDropdown.value = false
}

// Handle Click Away
const handleClickAway = () => {
  if (props.mode !== 'bulk-move') {
    handleSubmit()
  } else {
    handleClose()
  }
}

// Create Label
const createLabel = async () => {
  if (labelName.value && selectedColor.value) {
    store.commit('SET_NEW_LABEL', {
      name: labelName.value,
      color: selectedColor.value,
    })
    await store.dispatch('saveLabel')
    resetStates()

    // Wait for the label to be saved
    setTimeout(() => {
      // select the newly created label
      selectedLabels.value = [
        ...selectedLabels.value,
        store.getters.getLabels[0]?._id,
      ]
    }, 1000)
  }
}

// Handle Navigation
const handleNavigation = async () => {
  if (props.mode === 'composer') {
    let res = false
    if (isDraftComposer) {
      const options = {
        ...swalAttributes(),
        modalClass:
          'sweet-alert-confirmation fade sweet-alert-minimize-composer',
        okVariant: 'primary',
        hideHeader: false,
      }
      res = await $bvModal.msgBoxConfirm(
        'Your post will be saved and minimized while you make changes in Settings. You can continue editing afterward',
        {
          title: '⚠️ Unfinished Post is in the Composer!',
          ...options,
          cancelTitle: 'Cancel',
          okTitle: 'Proceed',
        }
      )
    }
    if (res === null || res === false) {
      return
    }
    emit('minimize-composer')
  }
  await router.push({ name: 'miscellaneous', hash: 'labels' })
}

const handleBulkDropdown = () => {
  if (props.mode === 'bulk-move') {
    showLabelDropdown.value = !showLabelDropdown.value
    getBulkLabelPlans()
  }
}

// Get key of object
const getObjectKey = (value) => {
  return Object.keys(LABELS_COLOR_MAP)?.find(
    (key) => LABELS_COLOR_MAP[key] === value
  )
}

// Handle Close
const handleClose = () => {
  showLabelDropdown.value = false
  selectedLabels.value = props.attachedLabels || []
  resetStates()
  resetSearch()
}

// Reset Search
const resetSearch = () => {
  searchQuery.value = ''
  isSearchActive.value = false
}

// Hide Color Picker
const hideColorPicker = () => {
  showColorPicker.value = false
}
</script>

<style scoped></style>
