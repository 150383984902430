<template>
  <clip-loader
    v-if="isLoading"
    class="spinner"
    :color="'#e0dfdf'"
    :size="'48px'"
  ></clip-loader>
  <blockquote
    class="instagram-media"
    data-instgrm-captioned
    :data-instgrm-permalink="postLink"
    data-instgrm-version="14"
    style="
      background: #fff;
      border: 0;
      border-radius: 3px;
      box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.5), 0 1px 10px 0 rgba(0, 0, 0, 0.15);
      margin: 1px;
      max-width: 540px;
      min-width: 326px;
      padding: 0;
      width: 99.375%;
      width: -webkit-calc(100% - 2px);
      width: calc(100% - 2px);
    "
  ></blockquote>
</template>

<script setup>
import { defineProps, onMounted, ref } from 'vue'

defineProps({
  postLink: {
    type: String,
    default: '',
  },
})

const isLoading = ref(true)
onMounted(() => {
  const loadInstagramScript = () => {
    if (!window.instgrm) {
      const script = document.createElement('script')
      script.async = true
      script.defer = true
      script.src = '//www.instagram.com/embed.js'
      document.body.appendChild(script)

      script.onload = () => {
        if (window.instgrm) {
          window.instgrm.Embeds.process()
          isLoading.value = false
        } else {
          console.error(
            'Instagram embed script loaded but window.instgrm is not available'
          )
        }
      }

      script.onerror = () => {
        console.error('Failed to load Instagram embed script')
        isLoading.value = false
      }
    } else {
      window.instgrm.Embeds.process()
      isLoading.value = false
    }
  }

  // Give some time for the script to load if it's already in the page
  setTimeout(loadInstagramScript, 1000)
})
</script>
