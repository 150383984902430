<script setup>
import { inject, onMounted, ref, nextTick, computed} from 'vue'
import { useRoute } from 'vue-router'
import CstButton from '@ui/Button/CstButton'
import SaveSocialAccounts from '@src/modules/integration/components/dialogs/SaveSocialAccounts'
import AccountsNotAllowedModal from '@src/modules/integration/components/modals/AccountsNotAllowedModal.vue'
import VideoIntro from '@/src/modules/account/views/onboarding/VideoIntro.vue'
import SocialConnect from '@/src/modules/account/views/onboarding/SocialConnect.vue'
import useSocialAccountModal from '@/src/modules/account/composables/useSocialAccountsModal'
import useWorkspace from '@/src/modules/common/composables/useWorkspace'
import { useStore } from '@/src/store/base'
import { useSteps, useOnboarding } from '@src/modules/account/composables/useUserOnboarding'
import WelcomeOnboard from "@modules/account/views/onboarding/WelcomeOnboard.vue";

// declarations
const store = useStore()
const route = useRoute()
const steps = store.getters.getActiveWorkspace?.onboarding_steps ?? {}
const signup_on_boarding = ref(store.getters.getProfile?.signup_on_boarding ?? {})
const { currentStep, stepsList, handleSteps } = useSteps()
const { userCredentials, localTimezone } = useOnboarding()
let modalTimeout
const root = inject('root')
const { $bvModal } = root
const { onboardingStepsCompleted } = useWorkspace()
const {
  hasConnectedAccounts,
  fetching,
  buttonText,
  OPTIONS,
  selectedOption,
  processReconnectResponse,
} = useSocialAccountModal()

const childRef = ref(null)

// methods
const handleButtonClick = async () => {
  if (!signup_on_boarding.value?.is_completed) {
    signup_on_boarding.value.is_completed = true
  }

  if(buttonText.value === 'Skip & Continue') {
    selectedOption.value = OPTIONS.VIDEO
  }

  if (selectedOption.value === OPTIONS.SOCIAL_CONNECT || steps.watch_video.status) {
    selectedOption.value = OPTIONS.SOCIAL_CONNECT
    $bvModal.hide('get-started-modal')
    return 0
  }

  if (selectedOption.value === OPTIONS?.VIDEO) {
    if (steps?.watch_video?.status) {
      selectedOption.value = OPTIONS.SOCIAL_CONNECT
      return
    }
    if (!steps.watch_video) {
      steps.watch_video = {}
    }
    steps.watch_video.status = true
    fetching.value = true
    onboardingStepsCompleted('watch_video')
    onboardingStepsCompleted('accounts_connection_modal_closed')
    fetching.value = false
  }
}

const showNextModal = () => {
  $bvModal.hide('get-welcome-modal')
  nextTick(() => {
    $bvModal.show('get-started-modal')
  })
}

const closeWelcomeModal = () => {
  clearTimeout(modalTimeout)
  showNextModal()
}

/**
 * This method is responsible for user onboarding state computation
 */
const computeUserOnboardingState = () => {

  localTimezone.value = store.getters.getActiveWorkspace.timezone
  const fullName = (`${store.getters.getProfile?.firstname || ''} ${store.getters.getProfile?.lastname || ''}`)

  userCredentials.timezone = store.getters.getActiveWorkspace.timezone
  userCredentials.firstname = userCredentials.firstname || fullName?.length > 1 ? fullName : ''
  userCredentials.lastname = userCredentials.lastname || store.getters.getProfile.lastname
  userCredentials.phone_no = userCredentials.phone_no || store.getters.getProfile.phone_no
  userCredentials.business_type = userCredentials.business_type || store.getters.getProfile.business_type

  if (signup_on_boarding.value && 'is_completed' in signup_on_boarding.value) {
    if (signup_on_boarding.value.is_completed) {
      stepsList.currentStepIndex = stepsList.steps.findIndex(step => step.key === 'social_connect');
      return;
    }

    const { credentials, business_type } = signup_on_boarding.value.steps || {};

    if (credentials && !business_type) {
      stepsList.currentStepIndex = stepsList.steps.findIndex(step => step.key === 'business_type');
      $bvModal.show('get-started-modal')
      return
    } else if (business_type) {
      stepsList.currentStepIndex = stepsList.steps.findIndex(step => step.key === 'social_connect');
      $bvModal.show('get-started-modal')
      return
    }


    $bvModal.show('get-welcome-modal')
    modalTimeout = setTimeout(showNextModal, 3000)

  }
}

const modalDialogClass = computed(() => {
  const baseClasses = 'xl:!max-w-[73rem] get-started-modal'
  return selectedOption.value !== OPTIONS.VIDEO
      ? `${baseClasses} hide-modal-close-button`
      : baseClasses
})

onMounted(async () => {
  computeUserOnboardingState()

  const response = await store.dispatch('checkConnectorState', {
    hash: route.hash,
  })

  await processReconnectResponse(response)
})
</script>

<template>
  <AccountsNotAllowedModal />
  <SaveSocialAccounts />
  <WelcomeOnboard :onClose="closeWelcomeModal" />
  <b-modal
      id="get-started-modal"
      body-class="!p-0"
      class="!w-[73rem]"
      :dialog-class="modalDialogClass"
      size="xl"
      centered
      no-close-on-backdrop
      hide-footer
      no-close-on-esc
      @hide="handleButtonClick"
  >
    <div class="bg-white lg:h-[41rem] rounded-tl-2xl rounded-tr-2xl">
      <!-- Body -->
      <VideoIntro v-if="selectedOption === OPTIONS.VIDEO" video-id="uAuiDkRGYhI" />

      <div v-else class="h-full">
        <!-- Dynamic Steps component importing -->
        <div v-if="signup_on_boarding?.steps && stepsList.currentStepIndex <= 2" class="flex items-center h-full rounded-md">
          <component ref="childRef" :is="currentStep.component" />
        </div>
        <div v-else class="h-full rounded-md">
          <component :is="SocialConnect" />
        </div>
        <!-- Dynamic Steps component importing end -->

      </div>
    </div>

    <!-- Steps progress -->
    <div class="flex justify-between items-center">
      <template v-if="signup_on_boarding?.steps && !signup_on_boarding?.is_completed">
        <div
            v-for="(step, index) in stepsList.steps.length"
            :key="index"
            :class="[
                  stepsList.currentStepIndex >= index ? 'bg-[#2A70FC]' : 'bg-gray-300'
                ]"
            class="h-[0.27rem] flex-grow transition-colors duration-300 ease-in-out">
        </div>
      </template>
    </div>
    <!-- Steps progress end -->
    <!-- Footer -->
    <div v-if="signup_on_boarding?.steps && !signup_on_boarding?.is_completed" :class="[stepsList.currentStepIndex > 0 ? 'justify-between' : 'justify-end']" class="flex items-center h-[5rem] gap-x-2 px-[2rem] bg-white rounded-bl-[1rem] rounded-br-[1rem]">
      <CstButton
          v-if="stepsList.currentStepIndex > 0"
          size="default"
          variant="outlined"
          class="h-[2.6rem] px-[2rem] flex items-center !rounded-lg !border !border-[#D2D5DF] !bg-[#FBFBFB]"
          @click="handleSteps(false)"
      >
        <p class="leading-4 font-normal text-[#454545]">Back</p>
      </CstButton>
      <CstButton
          v-if="stepsList.currentStepIndex < 2"
          size="default"
          variant="primary"
          text="Next"
          class="h-[2.6rem] px-[2rem] flex align-items-center !rounded-lg !border-primary-cs"
          @click.prevent="handleSteps(true)"
      />
      <CstButton
          v-else
          size="default"
          :variant="hasConnectedAccounts ? 'outlined' : 'primary'"
          :text="fetching ? 'Please wait...' : buttonText"
          class="h-[2.6rem] px-[2rem] flex align-items-center !rounded-lg !border-primary-cs"
          :disabled="fetching"
          @click.prevent="handleButtonClick"
      />
    </div>
    <div v-else-if="selectedOption === OPTIONS.VIDEO" class="flex items-center justify-center border-top h-[5rem] gap-x-2 px-[2rem] bg-white rounded-bl-[1rem] rounded-br-[1rem]">
      <p class="text-[#3C4549] text-center font-semibold">
        Watch this quick tutorial to know how ContentStudio can transform your productivity.
      </p>
    </div>
    <div v-else class="flex items-center justify-center border-top h-[5rem] gap-x-2 px-[2rem] bg-white rounded-bl-[1rem] rounded-br-[1rem]">
      <CstButton
          size="default"
          variant="primary"
          text="Continue"
          class="h-[2.6rem] px-[2rem] flex align-items-center !rounded-lg !border-primary-cs"
          :disabled="fetching"
          @click.prevent="handleButtonClick"
      />
    </div>
  </b-modal>
</template>

<style>
#get-started-modal___BV_modal_outer_ {
  z-index: 1051 !important;
}

#save-social-accounts___BV_modal_outer_ {
  z-index: 1052 !important;
}
#add-twitter-account-modal___BV_modal_outer_ {
  z-index: 1053 !important;
}

#accounts_not_allowed_modal___BV_modal_outer_ {
  z-index: 1053 !important;
}

#removeIntegration___BV_modal_outer_ {
  z-index: 1053 !important;
}
</style>

<style lang="scss">
.get-started-modal {
    .modal-content {
      background: transparent !important;
    }

    .modal-header {
      padding: 0 !important;
      background: transparent !important;
      border: none !important;
      position: relative;

      .close {
        position: absolute;
        background: white !important;
        margin: 0 !important;
        right: -10px;
        top: -10px;
        z-index: 9999;
        border-radius: 100% !important;
        width: 29px !important;
        height: 10px !important;
        padding-bottom: 16px !important;
      }
    }
}

.hide-modal-close-button {
  .modal-header {
    .close {
      display: none;
    }
  }
}
</style>
