<template>
  <div
    v-click-away="handleClickAway"
    v-tooltip.right="disabled ? disabledText : false"
    class="inline-block text-left"
    :class="{
      'z-100': isOpen,
      relative: parentPositionRelative,
      'grayscale filter cursor-not-allowed opacity-70': disabled,
    }"
  >
    <div>
      <button
        type="button"
        class="
          inline-flex
          overflow-hidden
          text-ellipsis
          whitespace-pre
          justify-between
          items-center
          w-full
          rounded-lg
          color-border
          shadow-xs
          bg-cs-light-gray
          font-normal
          text-gray-800
          hover:bg-gray-50
          focus:outline-none
        "
        :class="classObject"
        aria-expanded="true"
        aria-haspopup="true"
        @click="handleOpen"
      >
        <slot name="selected"> Select </slot>

        <span
          v-if="showArrow"
          class="transition leading-none ml-2.5"
          :class="{ 'fa-rotate-180': isOpen }"
        >
          <slot name="arrow">
            <i class="fa fa-angle-down text-lg"></i>
          </slot>
        </span>
      </button>
    </div>
    <DropdownTransition>
      <div
        v-show="isOpen"
        class="
          max-h-72
          overflow-y-auto
          origin-top-right
          absolute
          right-0
          w-full
          rounded-xl
          shadow-lg
          bg-white
          focus:outline-none
          z-50
        "
        :class="[
          { ...placementClassObject },
          containerClasses,
          dropdownClasses,
        ]"
        :style="containerStyle"
      >
        <div
          ref="dropdownList"
          class="py-1"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          :data-active="selected"
          tabindex="-1"
          @keydown="keyHandler"
        >
          <slot></slot>
        </div>
      </div>
    </DropdownTransition>
  </div>
</template>

<script>
import DropdownTransition from '@src/components/common/DropdownTransition.vue'

export default {
  name: 'CstDropdown',
  components: { DropdownTransition },
  provide() {
    return {
      ctx: this,
    }
  },
  props: {
    size: {
      type: String,
      default: 'default',
      validator: function (value) {
        // The value must match one of these strings
        return ['small', 'default', 'large'].indexOf(value) !== -1
      },
    },
    open: {
      type: Boolean,
      default: false,
    },
    buttonClasses: {
      type: String,
      default: '',
    },
    dropdownClasses: {
      type: String,
      default: '',
    },
    dropdownPlacement: {
      type: String,
      default: 'bottom',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    containerClasses: {
      type: String,
      default: '',
    },
    containerStyle: {
      type: Object,
      default: {},
    },
    showArrow: {
      type: Boolean,
      default: true,
    },
    disabledText: {
      type: String,
      default: '',
    },
    parentPositionRelative: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['onClose'],
  data() {
    return {
      isOpen: this.open,
      selected: 0,
    }
  },
  computed: {
    classObject: function () {
      return {
        'px-4 py-1.5 text-sm': this.size === 'small',
        'px-4 py-2 text-sm': this.size === 'default',
        'px-4 py-3 text-base': this.size === 'large',
        [this.buttonClasses]: true,
        'pointer-none': this.disabled,
      }
    },
    placementClassObject: function () {
      return {
        'bottom-full mb-2': this.dropdownPlacement === 'top',
        'mt-2': this.dropdownPlacement === 'bottom',
      }
    },
  },
  methods: {
    handleClickAway() {
      this.isOpen = false
      this.$emit('onClose')
    },
    handleOpen() {
      if (this.isOpen) {
        this.isOpen = false
        this.$emit('onClose')
      } else {
        this.isOpen = true
        setTimeout(() => {
          this.$refs.dropdownList?.focus()
          this.$refs.dropdownList
            ?.getElementsByClassName('active')[0]
            ?.scrollIntoView({ behavior: 'smooth' })
        }, 100)
      }
    },
    keyHandler(e) {
      console.log(e)
      if (e.keyCode === 38 && this.selected > 0) {
        e.preventDefault()
        this.selected--
      } else if (
        e.keyCode === 40 &&
        this.selected < this.$refs.dropdownList.children.length - 1
      ) {
        e.preventDefault()
        this.selected++
      }
    },
  },
}
</script>
