import { computed } from 'vue'
import { useStore } from '@state/base'
import {
  UNKNOWN_ERROR,
  COMPOSER_PUBLICATION_FOLDER_CREATED_SUCCESS,
  COMPOSER_PUBLICATION_FOLDER_CREATED_FAIL,
  COMPOSER_PUBLICATION_FOLDER_MOVE_FAIL,
  COMPOSER_PUBLICATION_FOLDER_MOVE_SUCCESS,
} from '@common/constants/messages'
import { EventBus } from '@common/lib/event-bus'
import { composer } from '@src/modules/composer/store/composer-mutation-type.js'
import {
  createPublicationFolderURL,
  updatePublicationFolderIdURL,
} from '@src/modules/composer/config/api-utils.js'
import useToast from '@common/composables/useToast'
import proxy from '@common/lib/http-common'

export function usePublicationFolder() {
  const {  getters, commit} = useStore()
  const { alertMessage } = useToast()
  const getWorkspaces = computed(() => getters.getWorkspaces)
  const getPublicationNewFolder = computed(
    () => getters.getPublicationNewFolder
  )

  const getMoveCampaign = computed(() => getters.getMoveCampaign)

  const createPublicationFolder = async (editId = '') => {
    console.log('Method:createPublicationFolder')
    commit(composer.SET_CREATE_PUBLICATION_FOLDER_BUTTON_LOADER, true)
    const payload = {
      workspace_id: getWorkspaces.value.activeWorkspace._id,
      folder: getPublicationNewFolder.value,
    }
    console.log('Method:createPublicationFolder payload => ', payload)
    if (editId) payload.editId = editId

    try {
      const response = await proxy.post(createPublicationFolderURL, payload)
      if (response.data.status === true) {
        if (payload.editId && payload.editId === 'edit_default_folder') {
          getWorkspaces.value.activeWorkspace.default_campaign_name =
            payload.folder.name
        }
        alertMessage(COMPOSER_PUBLICATION_FOLDER_CREATED_SUCCESS, 'success')
        const newFolder = {
          _id: response.data?.data?._id,
          name: response.data?.data?.name,
          counts: 0,
          color: response.data?.data?.color,
        }
        EventBus.$emit('createNewCampaignFolder', newFolder)
        // push new folder to the list
        getters?.getPublicationFolders?.unshift(newFolder)

        getPublicationNewFolder.value.name = ''
        if (getMoveCampaign.value && getMoveCampaign.value.campaignCreate) {
          if (response.data && response.data.data && response.data.data._id) {
            commit(composer.SET_MOVE_CAMPAIGN_ID, response.data.data._id)
          }
          commit(composer.SET_MOVE_CAMPAIGN_CREATE_STATUS, false)
        }
      } else {
        alertMessage(
          response.data.message || COMPOSER_PUBLICATION_FOLDER_CREATED_FAIL,
          'error'
        )
      }
    } catch (error) {
      console.debug('Exception in fetchPublicationFolders ', error)
      alertMessage(UNKNOWN_ERROR, 'error')
    } finally {
      commit(composer.SET_CREATE_PUBLICATION_FOLDER_BUTTON_LOADER, false)
    }
  }

  const movePostCampaign = async () => {
    console.log('Method:movePostCampaign ', getMoveCampaign.value)
    if(!getMoveCampaign.value.planId || !getMoveCampaign.value.id) {
        alertMessage('Please select a campaign to move', 'error')
        return
    }
    commit(composer.SET_MOVE_CAMPAIGN_LOADER, true)
    const payload = {
      workspace_id: getWorkspaces.value.activeWorkspace._id,
      planId: getMoveCampaign.value.planId,
      folderId: getMoveCampaign.value.id,
    }

    try {
      const response = await proxy.post(updatePublicationFolderIdURL, payload)
      if (response.data.status === true) {
        alertMessage(COMPOSER_PUBLICATION_FOLDER_MOVE_SUCCESS, 'success')
        EventBus.$emit('refreshPlannerTableV2')
        commit(composer.SET_MOVE_CAMPAIGN_DEFAULT)
        commit(composer.SET_PUBLICATIONS_SELECT_ALL, false)
        commit(composer.SET_PUBLICATIONS_BULK_IDS, [])
      } else {
        if (response.data.message) {
          alertMessage(response.data.message, 'error')
        } else {
          alertMessage(COMPOSER_PUBLICATION_FOLDER_MOVE_FAIL, 'error')
        }
      }
    } catch (error) {
      console.debug('Exception in fetchPublicationFolders ', error)
      alertMessage(UNKNOWN_ERROR, 'error')
      commit(composer.SET_CREATE_PUBLICATION_FOLDER_BUTTON_LOADER, false)
    } finally {
      commit(composer.SET_MOVE_CAMPAIGN_LOADER, false)
    }
  }

  return {
    createPublicationFolder,
    movePostCampaign,
  }
}
