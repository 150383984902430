<template>
  <div class="">
    <div
      v-if="getReplug.item._id"
      :class="{
        loader_overlay_with_loader: getFetchIntegrationsAccountsLoader,
      }"
    >
      <clip-loader
        v-if="getFetchIntegrationsAccountsLoader"
        color="#4165ed"
        :size="'16px'"
        class="d-inline-block align-middle"
      ></clip-loader>
      <div
        v-if="getReplug.brands.length > 0"
        :class="{ 'mt-3': nomargin, 'm-2': !nomargin }"
      >
        <div class="field_group">
          <select
            id=""
            v-model="getPublishSelection.replug.brand"
            name=""
            class="select_sm"
            @change="setSharingReplugCampaign('')"
          >
            <option value="">Select Brand</option>
            <option v-for="(item, index) in getReplug.brands" :key="index" :value="item">{{
              item.name
            }}</option>
          </select>
          <i class="icon-dropdown-cs"></i>
        </div>
      </div>

      <p
        v-else
        class="no_data_p text-center p-2"
        :class="{ 'text-center': textAlign }"
      >
        You do not have any Replug brand created.
      </p>

      <template v-if="getPublishSelection.replug.brand">
        <div
          v-if="getPublishSelection.replug.brand.campaign.length > 0"
          class="m-2"
        >
          <div class="field_group">
            <select
              id=""
              v-model="getPublishSelection.replug.campaign"
              name=""
              class="select_sm"
            >
              <option value="">Select Campaign</option>
              <option
                v-for="(item, key) in getPublishSelection.replug.brand.campaign"
                :key="key"
                :value="item"
              >
                {{ item.name
                }}<template v-if="item.cta_type"
                  >( {{ item.cta_type }} )</template
                >
              </option>
            </select>
            <i class="icon-dropdown-cs"></i>
          </div>
        </div>

        <p v-else class="text-center mt-3">
          You do not have any Replug campaign created.
        </p>
      </template>
    </div>

    <p
      v-else
      class="no_text_li"
      :class="{ 'text-center': textAlign, 'mt-3': nomargin }"
    >
      You have not connected replug yet.
      <button
        class="btn btn-studio-theme-space btn-size-small ml-2 mt-2"
        @click.prevent="connectAccount('replug', 'connect')"
        >Connect Account</button
      >
    </p>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import useIntegrationComposable from "@modules/integration/Composables/useIntegrationComposable";
export default {
  components: {},
  props: {
    type: {
      type: String,
      default: '',
    },
    nomargin: {
      type: Boolean,
      default: false,
    },
    textAlign: {
      type: Boolean,
      default: false,
    },
  },
  setup(){
    const {getAuthorizationUrl} = useIntegrationComposable()

    return {
      getAuthorizationUrl
    }
  },
  data () {
    return {
      bitly_link_visibility: true,
      replug_link_visibility: true
    }
  },
    computed: {
    ...mapGetters([
      'getPublishSelection',
      'getFetchIntegrationsAccountsLoader',
      'getReplug',
      'getWorkspaces'
    ]),
  },
  methods: {
    ...mapActions(['setSharingReplugCampaign']),
    async connectAccount (type, process, connectorId) {
      const details = { type, process }
      switch (type) {
        case 'bitly':
          this.bitly_link_visibility = false
          break
        case 'replug':
          this.replug_link_visibility = false
          break
      }
      if (typeof connectorId !== 'undefined') {
        details.connector_id = connectorId
      }

      const baseUrl = window.location.href.split('#')[0];
      details.callback_url = `${baseUrl}#${this.type}`;
      await this.getAuthorizationUrl({
        platform: type,
        connectionDetails: details,
      })
    }
  },
}
</script>
