// Importing necessary modules and components
import { computed, ref } from 'vue' // Vue composition API functions
import proxy from '@common/lib/http-common' // HTTP proxy for API calls
import moment from 'moment' // Date manipulation library
import { useStore } from '@state/base' // Vuex store
import useAnalytics from '@src/modules/analytics/components/common/composables/useAnalytics' // Custom analytics composable
import useNumber from '@common/composables/useNumber' // Number formatting utility
import useDateFormat from '@common/composables/useDateFormat' // Date formatting utility

// Importing icon assets
import FollowersGrowthIcon from '@/src/assets/img/icons/analytic/colored/followers-growth-icon.svg'
import FollowingColorIcon from '@/src/assets/img/icons/analytic/colored/following-icon.svg'
import EngagementPageIcon from '@/src/assets/img/icons/analytic/colored/engagement-page-icon.svg'
import EngagementsColorIcon from '@/src/assets/img/icons/analytic/colored/engagements-color-icon.svg'
import AccountEngagementsColorIcon from '@/src/assets/img/icons/analytic/colored/account-engagements-icon.svg'
import ReachColorIcon from '@/src/assets/img/icons/analytic/colored/reach-colored-icon.svg'
import ProfileImpressionsColorIcon from '@/src/assets/img/icons/analytic/colored/profile-impressions-colored-icon.svg'
import ImpressionsPostIcon from '@/src/assets/img/icons/analytic/colored/impressions-post-icon.svg'
import ViewsColorIcon from '@/src/assets/img/icons/analytic/colored/views-icon.svg'
import ReachIcon from '@/src/assets/img/icons/analytic/colored/reach-icon.svg'
import PostPublishedIcon from '@/src/assets/img/icons/analytic/colored/post-published-icon.svg'
import EngagementRateIcon from '@/src/assets/img/icons/analytic/colored/engagement-rate-colored-icon.svg'

// Importing helper functions
import { useComposerHelper } from '@/src/modules/composer_v2/composables/useComposerHelper'

// Importing more icon assets
import EngagementIcon from '@/src/assets/img/icons/analytic/plain/total-engagement.svg'
import CommentsIcon from '@/src/assets/img/icons/analytic/plain/comments-icon.svg'
import SavedIcon from '@/src/assets/img/icons/analytic/plain/saved-icon.svg'
import ImpressionsIcon from '@/src/assets/img/icons/analytic/plain/post-impressions.svg'
import PostTypeIcon from '@/src/assets/img/icons/analytic/plain/post-type.svg'
import HeartIcon from '@/src/assets/img/icons/analytic/plain/heart.svg'
import ReachPlainIcon from '@/src/assets/img/icons/analytic/plain/reach.svg'

// Importing utility functions
import useAnalyticsUtils from '@/src/modules/analytics/components/common/composables/useAnalyticsUtils'
const { momentWrapper } = useDateFormat()
const { camelToSnake } = useComposerHelper()
const { getAccountId, getPostImage } = useAnalyticsUtils()
const { formatNumber } = useNumber()
const { ANALYTICS_DELIMITER } = useAnalytics()

// Map to keep track of ongoing API requests
const ongoingRequests = new Map()

// Reactive references for selected account and posts limit
const selectedAccount = ref(null)
const postsLimit = ref(10)

// Reactive reference for loading states of various components
const isLoadingStates = ref({
  summary: false,
  overviewAudienceGrowth: false,
  overviewReach: false,
  overviewActiveUsers: false,
  overviewImpressions: false,
  overviewEngagement: false,
  overviewTopPosts: false,
  overviewAudienceLocation: false,
  overviewDemographics: false,
  overviewReelsAnalytics: false,
  overviewPublishingBehaviour: false,
  overviewStoryInsights: false,
  getTopPosts: false,
})

// Mapping of media types to their display names
const mediaTypeMap = {
  IMAGE: 'Image',
  VIDEO: 'Video',
  CAROUSEL_ALBUM: 'Carousel',
  STORY: 'Story',
  REELS: 'Reels',
}

// API endpoint routes for different data types
const routes = {
  SUMMARY: 'summary',
  OVERVIEW_ACTIVE_USERS: 'active_users',
  OVERVIEW_TOP_POSTS: 'top_posts',
  OVERVIEW_AUDIENCE_LOCATION: 'country_city',
  OVERVIEW_DEMOGRAPHICS: 'audience_age',
  OVERVIEW_AUDIENCE_GROWTH: 'audience_growth',
  OVERVIEW_PUBLISHING_BEHAVIOUR: 'publishing_behaviour',
  OVERVIEW_REELS_ANALYTICS: 'reels_performance',
  OVERVIEW_STORY_ANALYTICS: 'stories_performance',
  OVERVIEW_HASHTAGS: 'hashtags',
  TOP_POSTS: 'getTopPosts',
}

// Types of data rollups
const rollupTypes = {
  AUDIENCE: 'audience',
  REELS: 'reels',
  STORIES: 'stories',
  HASHTAGS: 'hashtags',
}

// Importing default date range from analytics module
const { DEFAULT_DATE_RANGE } = useAnalytics()

// Reactive reference for date range selection
const dateRange = ref(DEFAULT_DATE_RANGE)

// Reactive references for various data fetched from API
const cardsData = ref({})
const audienceGrowthData = ref({})
const activeUsersData = ref({})
const impressionsData = ref({})
const engagementsData = ref({})
const overviewTopPostsData = ref({})
const overviewAudienceLocationData = ref({})
const overviewDemographicsData = ref({})
const overviewReelsAnalyticsData = ref({})
const overviewStoryInsightsData = ref({})
const overviewPublishingBehaviourData = ref({})
const overviewHashtagsData = ref({})
const topPostsData = ref([])

// List of cards to display with their corresponding data keys, tooltips, and icons
const cards = ref([
  {
    title: 'Followers',
    key: 'followers_count',
    tooltip: `The total number of followers your account has gained from its creation to the selected end date.`,
    icon: FollowersGrowthIcon,
  },
  {
    title: 'Following',
    key: 'follows_count',
    tooltip: `The total number of accounts your profile has followed from its creation to the selected end date.`,
    icon: FollowingColorIcon,
  },
  {
    title: 'Accounts Engaged',
    key: 'accounts_engaged',
    tooltip: `The total number of unique accounts that interacted with your content from the time of connection with Content Studio to the selected end date.`,
    icon: AccountEngagementsColorIcon,
  },
  {
    title: 'Profile View',
    key: 'profile_views',
    tooltip: `The total number of times your profile was viewed from the time of connection with Content Studio to the selected end date.`,
    icon: ViewsColorIcon,
  },
  {
    title: 'Profile Reach',
    key: 'profile_reach',
    tooltip:
      'The total number of unique accounts that saw your profile content from the time of connection with Content Studio to the selected end date.',
    icon: ReachColorIcon,
  },
  {
    title: 'Profile Impression ',
    key: 'profile_impressions',
    tooltip:
      'The total number of times your content was displayed to audience from the time of connection with Content Studio to the selected end date.',
    icon: ProfileImpressionsColorIcon,
  },
  {
    title: 'Profile Engagement',
    key: 'profile_engagement',
    tooltip:
      'The total interactions (likes, comments, shares) on your profile content from the time of connection with Content Studio to the selected end date.',
    icon: EngagementPageIcon,
  },
  {
    title: 'Total Posts',
    key: 'doc_count',
    tooltip:
      'The total number of posts published within the selected date range.',
    icon: PostPublishedIcon,
  },
  {
    title: 'Post Engagement',
    key: 'post_engagement',
    tooltip:
      'The total number of interactions (likes, comments, saves) on posts published during the selected date range.',
    icon: EngagementsColorIcon,
  },
  {
    title: 'Post Impression',
    key: 'post_impressions',
    tooltip:
      "The total number of times your posts published during the selected time period entered a person's screen.",
    icon: ImpressionsPostIcon,
  },
  {
    title: 'Post Reach',
    key: 'post_reach',
    tooltip:
      'Reach indicates the total number of unique users who have seen your content posted during the selected the time period.',
    icon: ReachIcon,
  },
  {
    title: 'Engagements Rate',
    key: 'eng_rate',
    tooltip:
      'Average engagement received on each post published within the selected time period.',
    icon: EngagementRateIcon,
  },
])

// Keys for audience growth rollup data
const audienceRollupKeys = ref([
  {
    title: 'Followers',
    key: 'follower_count',
    tooltip:
      '<b>Followers</b><br/>Shows the total number of followers of you instagram page, along with the percentage increase or decrease compared to the previous period.',
  },
  {
    title: 'Followers Gained',
    key: 'follower_gained',
    tooltip:
      '<b>Followers Gained</b><br/>Shows the total number of new followers gained on your Instagram page during the selected period. This value can be positive (indicating an increase in followers) or negative (indicating a decrease in followers), along with the percentage change compared to the previous period.',
  },
])
// Keys for audience growth rollup data
const hashtagsRollupKeys = ref([
  {
    title: 'Hashtags',
    key: 'total_unique_hashtags',
    tooltip:
      '<b>Hashtag Used</b><br/>The total number of hashtags applied in your posts during the selected time period.',
  },
  {
    title: 'Times Used',
    key: 'total_hashtag_uses',
    tooltip:
      '<b>Times Used</b><br/>The total number of times hashtags were used across your posts within the selected time period.',
  },
  {
    title: 'Engagement',
    key: 'total_engagement',
    tooltip:
      '<b>Engagement</b><br/>The overall engagement (likes, comments, saves) generated by posts with hashtags during the selected time period.',
  },
  {
    title: 'Likes',
    key: 'total_likes',
    tooltip:
      '<b>Likes</b><br/>The total number of likes received on posts with hashtags within the selected time period.',
  },
  {
    title: 'Comments',
    key: 'total_comments',
    tooltip:
      '<b>Comments</b><br/>The total number of comments on posts with hashtags during the selected time period.',
  },
  {
    title: 'Saves',
    key: 'total_saves',
    tooltip:
      '<b>Saves</b><br/>The total number of times posts with hashtags were saved by users during the selected time period.',
  },
])

// Keys for reels rollup data
const reelsRollupKeys = ref([
  {
    title: 'Reels',
    key: 'total_posts',
    tooltip:
      '<b>Reels</b><br/>The total number of Reels published on your page during the specified period.',
  },
  {
    title: 'Watch Time (Sec)',
    key: 'total_watch_time',
    tooltip:
      '<b>Watch Time (Sec)</b><br/>The cumulative amount of time users have spent watching your Reels.',
  },
  {
    title: 'Avg. Time Watched',
    key: 'avg_watch_time',
    tooltip:
      '<b>Average Time Watched</b><br/>The average amount of time users have spent watching your Reels.',
  },
  {
    title: 'Engagements',
    key: 'engagement',
    tooltip:
      '<b>Engagements</b><br/>The total number of times your Reels have been played at least once.',
  },
  {
    title: 'Likes',
    key: 'likes',
    tooltip:
      '<b>Likes</b><br/>The total number of unique accounts that have seen your Reels.',
  },
  {
    title: 'Comments',
    key: 'comments',
    tooltip:
      '<b>Comments</b><br/>The total number of reactions (likes, loves, haha, wow, sad, angry) your Reels have received.',
  },
  {
    title: 'Saved',
    key: 'saves',
    tooltip: '<b>Saved</b><br/>The total number of comments left on your post.',
  },
  {
    title: 'Shares',
    key: 'shares',
    tooltip:
      '<b>Shares</b><br/>The number of times your Reels have been shared by users.',
  },
])

// Keys for stories rollup data
const storiesRollupKeys = ref([
  {
    title: 'Stories',
    key: 'published_stories',
    tooltip:
      '<b>Stories</b><br/>The total amount of stories published during the specified period.',
  },
  {
    title: 'Impressions',
    key: 'story_impressions',
    tooltip:
      '<b>Impressions</b><br/>The total number of times your stories, published during the specified period, have been viewed.',
  },
  {
    title: 'Avg. Impressions',
    key: 'avg_story_impressions',
    tooltip:
      '<b>Average Impressions</b><br/>The average number of times your stories, published during the specified period, have been viewed.',
  },
  {
    title: 'Reach',
    key: 'story_reach',
    tooltip:
      '<b>Reach</b><br/>The total number of unique accounts that have viewed your stories published during the specified period.',
  },
  {
    title: 'Taps Forward',
    key: 'story_taps_forward',
    tooltip:
      '<b>Taps Forward</b><br/>The total number of times users have tapped forward to view the next story in your sequence.',
  },
  {
    title: 'Taps Backward',
    key: 'story_taps_back',
    tooltip:
      '<b>Taps Backward</b><br/>The total number of times users have tapped backward to view the previous story in your sequence.',
  },
  {
    title: 'Exits',
    key: 'story_exits',
    tooltip:
      '<b>Exits</b><br/>The total number of times users have exited your story sequence.',
  },
  {
    title: 'Replies',
    key: 'story_reply',
    tooltip:
      '<b>Replies</b><br/>The total number of times users have replied to your stories published during the specified period.',
  },
])

// Function to get the formatted date range string
const getFormattedDateRange = () => {
  return dateRange.value
    .map((date) => momentWrapper(date).format('YYYY-MM-DD'))
    .join(' - ')
}

// Function to calculate growth and difference between current and previous values
const calculateGrowthAndDiff = (current, previous) => {
  if (!current || !previous) {
    return { growth: 0, difference: 0 }
  }
  const difference = current - previous || 0
  const divisor = previous > 0 ? previous : 1
  const growth =
    difference === 0 ? 0 : formatNumber((difference / divisor) * 100)
  return { growth, difference }
}

// Function to get data for a specific card
const getCardData = (card) => {
  const defaultValue = {
    title: card.title,
    total: 0,
    growth: 0,
    difference: 0,
    originalValue: 0,
    tooltip: 'Default tooltip',
    icon: card?.icon,
  }

  if (!cardsData.value || !Object.keys(cardsData.value)?.length) {
    return defaultValue
  }

  const { current, previous } = cardsData.value
  const { growth, difference } = calculateGrowthAndDiff(
    current?.[card.key],
    previous?.[card.key]
  )

  let dataObject = {}
  if (card.polarityKeys) {
    const positive = current?.[card.polarityKeys.positive] || 0
    const negative = current?.[card.polarityKeys.negative] || 0
    const previousPositive = previous?.[card.polarityKeys.positive] || 0
    const previousNegative = previous?.[card.polarityKeys.negative] || 0

    const total = positive + negative
    const previousTotal = previousPositive + previousNegative
    const { growth, difference } = calculateGrowthAndDiff(total, previousTotal)

    dataObject = {
      showPositiveNegative: true,
      positive: formatNumber(positive),
      negative: formatNumber(negative),
      total: formatNumber(total),
      growth: growth || defaultValue.growth,
      difference: difference || defaultValue.difference,
      originalValue: total || defaultValue.originalValue,
    }
  } else {
    dataObject = {
      total: formatNumber(+current?.[card.key]) || defaultValue.total,
      growth: growth || defaultValue.growth,
      difference: difference || defaultValue.difference,
      originalValue: current?.[card.key] || defaultValue.originalValue,
    }
  }

  return {
    title: card.title,
    tooltip: card.tooltip,
    icon: card.icon,
    ...dataObject,
  }
}

// Function to get rollup data for audience, reels, or stories
const getRollupData = (dataSource, card, key) => {
  const defaultValue = {
    title: card.title,
    total: 0,
    growth: 0,
    difference: 0,
    originalValue: 0,
    tooltip: '',
  }

  const rollupData = dataSource.value?.[key]
  if (!rollupData) {
    return defaultValue
  }

  const { current = {}, previous = {} } = rollupData

  const { growth, difference } = calculateGrowthAndDiff(
    current?.[card.key],
    previous?.[card.key]
  )

  return {
    title: card.title,
    total: formatNumber(current?.[card.key]) || defaultValue.total,
    growth: growth || defaultValue.growth,
    difference: difference || defaultValue.difference,
    originalValue: current?.[card.key] || defaultValue.originalValue,
    tooltip: card?.tooltip || defaultValue.tooltip,
  }
}

// List of valid headers for the posts table
const validPostsTableHeaders = [
  'thumbnail',
  'mediaType',
  'impressions',
  'reach',
  'engagement',
  'likeCount',
  'commentsCount',
  'saved',
]

// List of valid headers for the posts table in report view
const validPostsTableHeadersReport = [
  'thumbnail',
  'mediaType',
  'impressions',
  'reach',
  'engagement',
  'likeCount',
  'commentsCount',
  'saved',
]

// Headers that are not sortable
const nonSortableItems = ['thumbnail', 'description', 'mediaType']

// Object mapping of header values to their corresponding titles
const headerTitles = {
  thumbnail: 'Posts',
  mediaType: 'Media Type',
  engagement: 'Engagements',
  impressions: 'Impressions',
  reach: 'Reach',
  likeCount: 'Likes',
  commentsCount: 'Comments',
  saved: 'Saved',
}

// Object mapping of header values to their corresponding tooltips
const headerTooltips = {
  mediaType: 'The format of the post—Reels, Image, Carousel, or Video.',
  engagement:
    'The total interactions (likes, comments, saves) this post received.',
  impressions: 'The total number of times this post was displayed to users.',
  reach: 'The total number of unique accounts that saw this post.',
  likeCount: 'The total number of likes this post received.',
  commentsCount: 'The total number of comments left on this post.',
  saved: 'The total number of times this post was saved by users.',
}

// Object mapping of header values to their corresponding formatting methods
const bodyValuesMap = {
  thumbnail: (value) => value,
  mediaType: (value) => value,
  description: (value) => value,
  engagement: (value) => formatNumber(value),
  impressions: (value) => formatNumber(value),
  reach: (value) => formatNumber(value),
  likeCount: (value) => formatNumber(value),
  commentsCount: (value) => formatNumber(value),
  saved: (value) => formatNumber(value),
}

// Method to get the title corresponding to a given header value
const getHeaderTitles = (header = '') => {
  return headerTitles[header] || ''
}

// Method to get the tooltip corresponding to a given header value
const getHeaderTooltips = (header = '') => {
  return headerTooltips[header] || ''
}

// Method to get the formatted value for a given header and its corresponding value
const getBodyValues = (header = '', value = '') => {
  return bodyValuesMap[header] ? bodyValuesMap[header](value) : ''
}

// Fields to display in the post modal
const postModalFields = [
  {
    label: 'Engagements',
    key: 'engagement',
    iconSrc: EngagementIcon,
    iconClass: 'scale-[1.2]',
    tooltip: `The total interactions (likes, comments, saves) this post received.`,
  },
  {
    label: 'Impressions',
    key: 'impressions',
    iconSrc: ImpressionsIcon,
    iconClass: '',
    tooltip: `The total number of times this post was displayed to users.`,
  },
  {
    label: 'Reach',
    key: 'reach',
    iconSrc: ReachPlainIcon,
    iconClass: 'scale-[1.2]',
    tooltip: `The total number of unique accounts that saw this post.`,
  },
  {
    label: 'Likes',
    key: 'likeCount',
    iconSrc: HeartIcon,
    iconClass: '',
    tooltip: `The total number of likes this post received.`,
  },
  {
    label: 'Comments',
    key: 'commentsCount',
    iconSrc: CommentsIcon,
    iconClass: '',
    tooltip: `The total number of comments left on this post.`,
  },
  {
    label: 'Saved',
    key: 'saved',
    iconSrc: SavedIcon,
    iconClass: '',
    tooltip: `The total number of times this post was saved by users.`,
  },
  {
    label: 'Type',
    key: 'mediaType',
    iconSrc: PostTypeIcon,
    iconClass: '',
    tooltip: `The format of the post—Reels, Image, Carousel, or Video.`,
  },
]

// Function to get rollup data keys and data retrieval function based on type
const getRollup = (type) => {
  switch (type) {
    case rollupTypes.AUDIENCE:
      return {
        keys: audienceRollupKeys.value,
        getData: (card) =>
          getRollupData(audienceGrowthData, card, 'audience_growth_rollup'),
      }
    case rollupTypes.REELS:
      return {
        keys: reelsRollupKeys.value,
        getData: (card) =>
          getRollupData(overviewReelsAnalyticsData, card, 'reels_rollup'),
      }
    case rollupTypes.STORIES:
      return {
        keys: storiesRollupKeys.value,
        getData: (card) =>
          getRollupData(overviewStoryInsightsData, card, 'stories_rollup'),
      }
    case rollupTypes.HASHTAGS:
      return {
        keys: hashtagsRollupKeys.value,
        getData: (card) =>
          getRollupData(overviewHashtagsData, card, 'hashtags_rollup'),
      }
    default:
      return {
        keys: [],
        getData: () => {},
      }
  }
}

// Function to get the API key for a header, used in sorting
const getHeaderApiKey = (header) => {
  if (tableSortableItems.value.includes(header)) {
    return camelToSnake(header)
  }
  return ''
}

// Computed property to check if media can be fetched
const canFetchMedia = computed(() => {
  return !!(
    selectedAccount.value !== undefined &&
    selectedAccount.value !== null &&
    dateRange.value.length > 0
  )
})

// Computed property to get fan count (number of followers)
const fanCount = computed(() => {
  return cardsData.value?.current?.followers_count || 0
})

// Computed property to get sortable table items
const tableSortableItems = computed(() => {
  return validPostsTableHeaders.filter(
    (header) => !nonSortableItems.includes(header)
  )
})

// Function to set loading state for a specific type
const setIsloading = (type, value) => {
  isLoadingStates.value[type] = value
}

// Function to transform the fetched posts data
const transformObject = (data) => {
  // Assuming data is an array of posts
  return data.map((post) => {
    const thumbnail = getPostImage('instagram', post)
    return {
      id: post.instagram_id,
      mediaId: post.media_id,
      username: post.username,
      name: post.name,
      profilePictureUrl: post.profile_picture_url,
      permalink: post.permalink,
      likeCount: post.like_count,
      commentsCount: post.comments_count,
      engagement: post.engagement,
      impressions: post.impressions,
      reach: post.reach,
      saved: post.saved,
      videoViews: post.video_views,
      exits: post.exits,
      replies: post.replies,
      description: post.caption,
      mediaType: mediaTypeMap[post.media_type],
      entityType: post.entity_type,
      hashtags: post.hashtags,
      dayOfWeek: post.day_of_week,
      hourOfDay: post.hour_of_day,
      year: post.year,
      month: post.month,
      timestamp: post.timestamp,
      storedEventAt: post.stored_event_at,
      postCreatedAt: post.post_created_at,
      mediaUrl: post.media_url,
      videoUrl: post.video_url,
      childAssetsType: post.child_assets_type,
      reelsShares: post.reels_shares,
      reelsAvgWatchTime: post.reels_avg_watch_time,
      reelsTotalWatchTime: post.reels_total_watch_time,
      totalEngagement: post.total_engagement,
      thumbnail,
    }
  })
}

/**
 * Composable function for Instagram Analytics
 * @param {String} defaultLabel - Default label for data (optional)
 * @returns {Object} - Contains reactive properties and methods for Instagram analytics
 */
export default function useInstagramAnalytics(defaultLabel = 'Data') {
  // Access Vuex store getters
  const { getters } = useStore()

  // Destructure functions and constants from useAnalytics composable
  const {
    dataZoomOptions,
    barChartOptions,
    analyticsDesignSystem,
    BASE_ENDPOINT,
    lineChartOptions,
    pieChartOptions,
    multipleSeriesBarChartOptions,
    legendOptions,
    multipleSeriesLineChartOptions,
    defaultChartOptions,
    isReportView,
    screenWidth,
    isNoAnalyticsData,
    axisLabelFormatter,
    tooltipFormatterScroll,
    generateStackedChartOptions,
  } = useAnalytics(defaultLabel)

  /**
   * Function to fetch media data based on type
   * @param {String} type - Type of data to fetch (e.g., 'summary', 'top_posts')
   * @param {Object} extraPayload - Additional payload for the request
   * @param {Boolean} append - Whether to append data or not
   */
  const fetchMedia = async (type, extraPayload = {}, append = false) => {
    if (canFetchMedia.value) {
      // Check if there's an ongoing request of the same type and abort it
      if (ongoingRequests.has(type)) {
        ongoingRequests.get(type).abort()
      }

      // Create a new AbortController for the new request
      const controller = new AbortController()
      const { signal } = controller
      ongoingRequests.set(type, controller)

      try {
        setIsloading(type, true)
        const payload = {
          workspace_id: getters.getActiveWorkspace._id,
          accounts: [getAccountId(selectedAccount.value)],
          facebook_accounts: [],
          twitter_accounts: [],
          instagram_accounts: [],
          pinterest_accounts: [],
          linkedin_accounts: [],
          tiktok_accounts: [],
          youtube_accounts: [],
          date: getFormattedDateRange(),
          timezone: getters.getActiveWorkspace.timezone,
          type,
          ...extraPayload,
        }
        const { data } = await proxy.post(
          BASE_ENDPOINT + 'instagram/' + type,
          payload,
          { signal }
        )

        // Handle the data based on the type
        switch (type) {
          case routes.SUMMARY:
            cardsData.value = data?.overview?.summary || {}
            break
          case routes.OVERVIEW_AUDIENCE_GROWTH:
            audienceGrowthData.value = data?.overview
            break
          case routes.OVERVIEW_ACTIVE_USERS:
            activeUsersData.value = data?.overview
            break
          case routes.OVERVIEW_TOP_POSTS:
            overviewTopPostsData.value = data?.top_posts || []
            break
          case routes.OVERVIEW_AUDIENCE_LOCATION:
            overviewAudienceLocationData.value = data || {
              audience_city: {},
              audience_country: {},
            }
            break
          case routes.OVERVIEW_DEMOGRAPHICS:
            overviewDemographicsData.value = data || {}
            break
          case routes.OVERVIEW_REELS_ANALYTICS:
            overviewReelsAnalyticsData.value = data?.overview || {}
            break
          case routes.OVERVIEW_STORY_ANALYTICS:
            overviewStoryInsightsData.value = data?.overview || {}
            break
          case routes.OVERVIEW_PUBLISHING_BEHAVIOUR:
            overviewPublishingBehaviourData.value = data?.overview || {}
            break
          case routes.OVERVIEW_HASHTAGS:
            overviewHashtagsData.value = data?.overview || {}
            break
          case routes.TOP_POSTS:
            topPostsData.value = transformObject(data?.top_posts) || []
            break
          default:
            break
        }
      } catch (e) {
        if (e.name === 'CanceledError') {
          console.log(`Request for ${type} was aborted`)
          return
        } else {
          console.error('FETCH DATA:::', e)
        }
      }
      setIsloading(type, false)
      // Clean up the ongoing request map
      ongoingRequests.delete(type)
    }
  }

  /**
   * Function to format time string to readable format
   * @param {String} timeString - Time string to format
   * @returns {String} - Formatted time string
   */
  const getTimeFormatted = (timeString) => {
    return moment(timeString).format('MMMM Do, YYYY hh:mm A')
  }

  // Return all reactive properties and methods
  return {
    // Constants
    ANALYTICS_DELIMITER,
    analyticsDesignSystem,
    // Variables
    routes,
    rollupTypes,
    legendOptions,
    dataZoomOptions,
    defaultChartOptions,
    postsLimit,
    postModalFields,
    tableSortableItems,
    // State
    fanCount,
    selectedAccount,
    cards,
    dateRange,
    barChartOptions,
    lineChartOptions,
    pieChartOptions,
    multipleSeriesLineChartOptions,
    multipleSeriesBarChartOptions,
    isReportView,
    screenWidth,
    isLoadingStates,
    audienceGrowthData,
    activeUsersData,
    impressionsData,
    engagementsData,
    overviewTopPostsData,
    overviewAudienceLocationData,
    overviewDemographicsData,
    overviewReelsAnalyticsData,
    overviewStoryInsightsData,
    overviewPublishingBehaviourData,
    overviewHashtagsData,
    topPostsData,
    audienceRollupKeys,
    validPostsTableHeaders,
    validPostsTableHeadersReport,
    nonSortableItems,
    mediaTypeMap,
    cardsData,
    // Methods
    fetchMedia,
    getCardData,
    isNoAnalyticsData,
    axisLabelFormatter,
    getTimeFormatted,
    tooltipFormatterScroll,
    setIsloading,
    getRollup,
    getHeaderTitles,
    getHeaderTooltips,
    getBodyValues,
    getHeaderApiKey,
    generateStackedChartOptions,
  }
}
