<template>
  <div class="w-[63.5rem]">
    <div class="h-full w-full left_section">
      <div class="flex flex-col h-full justify-between py-[4.6rem] ">
        <div class="px-[5rem]">
          <LogoComponent />
        </div>
        <div class="flex-grow flex flex-col justify-center">
          <div class="px-[5rem] mb-10 xl:mb-20">
            <div class="flex items-center px-5 w-max py-1 gap-x-2  text-[#0D2859] mb-4 bg-white rounded-full">
              <img
                  class="w-5"
                  draggable="false"
                  src="@assets/img/onboarding/crown.svg"
                  alt="crown"
              />
              New Feature
            </div>
            <h2 class="text-left text-[#0D2859] font-bold text-3xl">Integrating Meta Threads</h2>
            <p class="text-[#0D2859] leading-6 text-lg mt-2">We're excited to announce Meta Threads integration! Look out for it in our upcoming update</p>
          </div>
          <img
              class="w-full px-14 object-contain"
              draggable="false"
              src="@assets/img/new-features/feature.svg"
              alt="Aug 2024 Product Updates"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import LogoComponent from "@modules/account/components/LogoComponent.vue";
</script>
<style lang="scss" scoped>
::v-deep .vti__dropdown{
  width: 64px;
}

::v-deep .vti__dropdown,
::v-deep .vti__input {
  height: 3.275rem !important
}

::v-deep .VueCarousel-pagination {
  justify-content: center;
  margin-bottom: 1rem;
}


@media screen and (max-width: 1472px) {
  .auth-buttons {
    flex-direction: column;

    span {
      width: 100%;
      justify-content: center;
    }
  }
}

.feature-img-container {
  max-width: 66rem !important;
}

.auth-form-container {
  border-radius: 0 !important;
  padding: 4rem 5.4rem !important;

  .auth-form__social-field {
    margin-top: 2.3rem;
    margin-bottom: 3rem;
    text-align: center;
  }

  .custom-border {
    border-bottom: 1px solid #cfcece92;
    border-radius: 50%;
  }
}

.left_section {
  background: linear-gradient(22deg, #C8E8FF 0%, #EEF4F7 32.18%, #EEF0F7 95.94%);
  box-shadow: 0px 0px 0px 1px #D1E3FB;

  .trusted_section {
    border-radius: 7.24px;
    border-top: 1px solid rgba(255, 255, 255, 0.30);
    background: radial-gradient(314.45% 139.15% at 3.59% 3.24%, rgba(255, 255, 255, 0.35) 0%, rgba(255, 255, 255, 0.07) 100%);
    backdrop-filter: blur(14.395000457763672px);
  }
}

</style>
