<script setup>
import { ref, computed, watch } from 'vue'
import AnalyticsCardWrapper from '@src/modules/analytics_v3/components/AnalyticsCardWrapper.vue'
import MainGraph from '@src/modules/analytics/views/instagram_v2/components/graphs/MainGraph.vue'
import SkeletonBox from '@src/modules/analytics/views/common/SkeletonBox.vue'
import useInstagramAnalytics from '@src/modules/analytics/views/instagram_v2/composables/useInstagramAnalytics.js'

// state
const {
  barChartOptions: chartOptions,
  isReportView,
  postCount,
  legendOptions,
  overviewHashtagsData,
  routes,
  isLoadingStates,
} = useInstagramAnalytics('Hashtags')

const openedModal = ref(false)

const isNoData = computed(() => {
  const data = overviewHashtagsData.value?.hashtags
  const isAllZero =
    data?.engagement?.every((value) => value === 0) &&
    data?.posts?.every((value) => value === 0)
  return data?.name?.length === 0 || isAllZero
})

watch(
  () => [overviewHashtagsData.value, openedModal.value],
  () => {
    const slicingValue = openedModal.value ? 30 : 10
    const data = overviewHashtagsData.value?.hashtags
    const hashtagData =
      data?.name
        ?.map((name, index) => ({
          name,
          likes: data.likes[index],
          comments: data.comments[index],
          saved: data.saved[index],
          posts: data.posts[index],
        }))
        .slice(0, slicingValue) || []
    chartOptions.value.xAxis.data = hashtagData.map((item) => item.name)
    chartOptions.value.xAxis.axisLabel.formatter = null

    const yAxisFirstIndex = Array.isArray(chartOptions.value?.yAxis)
      ? chartOptions.value.yAxis[0]
      : chartOptions.value.yAxis
    const yAxisSecondIndex = Array.isArray(chartOptions.value?.yAxis)
      ? chartOptions.value.yAxis[1]
      : chartOptions.value.yAxis

    chartOptions.value.grid = {
      ...chartOptions.value.grid,
      right: 30,
    }

    chartOptions.value.xAxis = {
      ...chartOptions.value.xAxis,
      type: 'value',
      axisLabel: {
        show: false,
      },
      axisPointer: {
        show: false,
      },
      splitLine: {
        lineStyle: {
          color: '#F2F4F6',
        },
      },
    }

    chartOptions.value.yAxis = [
      {
        type: 'category',
        data: hashtagData.map((item) => item.name),
        axisLabel: {
          ...yAxisFirstIndex.axisLabel,
          formatter: (value) => `#${value}`,
        },
        axisPointer: {
          type: 'shadow',
        },
      },
      {
        ...yAxisSecondIndex,
        name: '',
        type: 'category',
        position: 'right',
        data: hashtagData.map((item) => item.posts),
        axisLabel: {
          ...yAxisSecondIndex.axisLabel,
          formatter: (value) => (value !== 'null' ? value : '0'),
        },
        axisPointer: {
          type: 'shadow',
          label: {
            show: false,
          },
        },
      },
    ]

    chartOptions.value.series = [
      {
        name: 'Likes',
        type: 'bar',
        stack: 'engagement',
        itemStyle: { color: '#8AC1A2' },
        data: hashtagData.map((item) => item.likes),
      },
      {
        name: 'Comments',
        type: 'bar',
        stack: 'engagement',
        itemStyle: { color: '#8081B9' },
        data: hashtagData.map((item) => item.comments),
      },
      {
        name: 'Saved',
        type: 'bar',
        stack: 'engagement',
        itemStyle: { color: '#F2CA6B' },
        data: hashtagData.map((item) => item.saved),
      },
      {
        name: 'Times Used',
        type: 'bar',
        itemStyle: { color: '#8ABAF3' },
        data: hashtagData.map((item) => item.posts),
      },
    ]

    chartOptions.value.grid = {
      ...chartOptions.value.grid,
      right: 30,
    }

    chartOptions.value.legend = {
      ...legendOptions,
      data: ['Likes', 'Comments', 'Saved', 'Times Used'],
    }
  },
  {
    deep: true,
    immediate: true,
  }
)
const isLoading = computed(
  () => isLoadingStates.value?.[routes.OVERVIEW_HASHTAGS]
)
</script>

<template>
  <AnalyticsCardWrapper
    type="insta-hashtags"
    :enable-modal="true"
    :is-modal="openedModal"
    @toggle-is-modal="(value) => (openedModal = value)"
  >
    <template v-slot:card-header>
      <div class="w-full flex justify-between items-center">
        <div class="flex items-center gap-2">
          <div class="flex align-items-center gap-2">
            <h2 class="text-base font-weight-500 select-none">
              Top Hashtags
            </h2>
            <v-menu
              v-if="!isReportView"
              :popper-triggers="['hover']"
              placement="top"
              popper-class="first-comment__info-popover"
              :delay="300"
            >
              <i class="far fa-question-circle p-0 cursor-pointer"></i>
              <template v-slot:popper>
                <p class="text-sm text-gray-900">
                  Displays the top hashtags used in your posts, showing
                  engagement and usage frequency. This data provides insights
                  into which hashtags are most effective for your content.
                </p>
              </template>
            </v-menu>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:card-body="slotProps">
      <div class="flex-1">
        <SkeletonBox
          v-if="isLoading"
          width="100%"
          height="350px"
          radius="8px"
        />
        <MainGraph
          v-else-if="!isNoData"
          :key="Math.random()"
          type="bar"
          :chart-options="
            slotProps.isModal
              ? {
                  ...chartOptions,
                  dataZoom: [
                    {
                      type: 'inside',
                      orient: 'vertical',
                    },
                    {
                      type: 'slider',
                      orient: 'vertical',
                    },
                  ],
                  grid: { ...chartOptions.grid, bottom: 70, right: 70 },
                }
              : chartOptions
          "
          :is-modal="slotProps.isModal"
        />
        <div v-else class="analytics-no-data-found">
          <img
            src="@src/assets/img/no_data_images/no-analytical-data-available.svg"
            alt=""
          />
          <p v-if="postCount <= 10"
            >You'll need at least 10 posts with hashtags to view this
            information.</p
          >
          <p v-else>No data found.</p>
        </div>
      </div>
    </template>
  </AnalyticsCardWrapper>
</template>
