<script setup>
import { inject, ref } from 'vue'
// import CstSwitch from '@ui/Switch/CstSwitch.vue'
import useWhiteLabel from '@modules/setting/composables/whitelabel/useWhiteLabel'
import CstButton from '@ui/Button/CstButton.vue'

const root = inject('root')
const { $bvModal } = root
const showCopiedMessage = ref(false)

const { isWhiteLabelEnabled, isSetupComplete, whiteLabelUrl, sendEmail } =
  useWhiteLabel()

const shouldSendEmail = ref(false)

const copyToClipboard = () => {
  if (isSetupComplete.value) {
    navigator.clipboard.writeText(whiteLabelUrl.value).then(() => {
      showCopiedMessage.value = true
      setTimeout(() => {
        showCopiedMessage.value = false
      }, 2000)
    })
  }
}

const openInNewTab = () => {
  if (isSetupComplete.value) {
    window.open(whiteLabelUrl.value, '_blank', 'noopener,noreferrer')
  }
}
</script>

<template>
  <b-modal
    id="white-label-completion"
    centered
    hide-footer
    hide-header
    no-close-on-backdrop
  >
    <div class="pt-9 pb-8 px-7 relative">
      <!-- Close Button -->
      <div
        v-tooltip="'Close'"
        class="absolute bg-gray-200 cursor-pointer flex items-center justify-center p-2.5 right-4 rounded-lg top-4"
        @click="$bvModal.hide('white-label-completion')"
      >
        <img
          alt="close icon"
          class="h-3 w-3"
          src="@assets/img/icons/close_cross.svg"
        />
      </div>

      <!-- Icon -->
      <div class="flex justify-center mb-7">
        <img
          alt="white label successful icon"
          src="@assets/img/settings/white-label/success.svg"
        />
      </div>

      <!-- Title -->
      <h2 class="text-2xl font-bold text-center text-[#3A4557] mb-4"
        >White Label {{ isWhiteLabelEnabled ? 'Activated' : 'Activation' }}</h2
      >

      <!-- Message -->
      <p v-if="isSetupComplete" class="text-center text-[#595C5F] mb-7">
        Your White Label settings have been completed and saved successfully!
      </p>

      <!--      &lt;!&ndash; Activate Toggle &ndash;&gt;-->
      <!--      <div-->
      <!--        v-tooltip="{-->
      <!--          content: isWhiteLabelEnabled-->
      <!--            ? 'Contact support to disable White Label'-->
      <!--            : `${-->
      <!--                isSetupComplete-->
      <!--                  ? 'Enable White Label'-->
      <!--                  : 'Complete the setup to enable White Label'-->
      <!--              }`,-->
      <!--        }"-->
      <!--        :class="{-->
      <!--          'cursor-not-allowed opacity-50': !isSetupComplete || isWhiteLabelEnabled,-->
      <!--        }"-->
      <!--        class="flex items-center justify-center mb-7"-->
      <!--      >-->
      <!--        <CstSwitch-->
      <!--          v-model="isWhiteLabelEnabled"-->
      <!--          :disabled="!isSetupComplete || isWhiteLabelEnabled"-->
      <!--          size="small"-->
      <!--          @input="handleWhiteLabelStatusChange"-->
      <!--        />-->
      <!--        <span class="ml-3 text-gray-700">Activate</span>-->
      <!--      </div>-->

      <div class="relative">
        <div
          v-if="!isSetupComplete || !isWhiteLabelEnabled"
          v-tooltip="{
            content: !isSetupComplete
              ? 'Complete the setup to use White Label'
              : 'Activate White Label to use this feature',
          }"
          class="absolute w-full h-full z-10 opacity-50 !cursor-not-allowed bg-white"
        >
        </div>

        <!-- Options -->
        <div class="flex justify-between items-center mb-6 px-3">
          <label
            for="send-email"
            class="flex items-center gap-2 cursor-pointer"
          >
            <input
              id="send-email"
              v-model="shouldSendEmail"
              type="checkbox"
              class="rounded w-3.5 h-3.5"
            />
            <span class="text-[#454545] text-sm leading-none"
              >Notify all team members via email</span
            >
          </label>
          <button
            v-if="shouldSendEmail"
            class="flex items-center gap-1 border py-1.5 px-2 bg-white rounded-lg cursor-pointer"
            @click.prevent="sendEmail"
          >
            <img
              alt="email icon"
              class="mr-1.5 w-3.5 h-3.5"
              src="@assets/img/settings/white-label/email.svg"
            />
            <span class="text-xs text-[#454545]"> Send Email </span>
          </button>
        </div>

        <!-- URL Input -->
        <div class="flex items-center mb-7 relative">
          <img
            alt=""
            class="w-5 h-5 absolute left-3 top-3"
            src="@src/assets/img/integration/copy_url.svg"
          />
          <input
            id="white-label-url"
            v-model="whiteLabelUrl"
            class="flex-grow bg-[#F8F8F8] rounded pl-10 pr-20 py-2.5 border-none !cursor-default"
            readonly
            type="text"
          />
          <div
            class="absolute right-3 border py-1 px-2 bg-white rounded-lg cursor-pointer"
            @click="copyToClipboard"
          >
            <img
              alt=""
              class="w-4 h-4"
              src="@src/assets/img/integration/copy_url.svg"
            />
            <span
              :class="[showCopiedMessage ? 'text-green-600' : 'text-[#454545]']"
              class="text-xs ml-2"
            >
              {{ showCopiedMessage ? 'Copied!' : 'Copy' }}
            </span>
          </div>
        </div>
      </div>

      <!-- Action Buttons -->
      <CstButton
        :disabled="!isSetupComplete"
        class="w-full"
        @click="openInNewTab"
      >
        Let's Go To White Label!
      </CstButton>
    </div>
  </b-modal>
</template>
