<template>
  <div class="w-[63.5rem]">
    <div class="h-full w-full left_section">
      <div class="flex flex-col h-full justify-between py-[4.6rem] ">
        <div class="px-[5rem] py-[2rem]">
          <LogoComponent />
        </div>
        <div class="px-[5rem] flex-grow flex flex-col justify-center">
          <SignupTestimonial />
        </div>
        <div class="px-14">
          <div class="trusted_section py-10 px-2">
            <h2 class="text-[#3C3C3C] text-[1.5rem] font-bold text-center">Trusted brands and Agencies </h2>
            <div class="flex items-center justify-around mt-4">
              <img class="w-24" src="@assets/img/onboarding/unilever.svg" alt="unilever"/>
              <img class="w-24" src="@assets/img/onboarding/mintsocial.png" alt="mintsocial"/>
              <img class="w-24" src="@assets/img/onboarding/moderntv.png" alt="moderntv"/>
            </div>
            <div class="flex items-center justify-around mt-4">
              <img class="w-24" src="@assets/img/onboarding/decathlon.svg" alt="decathlon"/>
              <img class="w-24" src="@assets/img/onboarding/getdone.png" alt="getdone"/>
              <img class="w-24" src="@assets/img/onboarding/ecocarrier.png" alt="ecocarrier"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
const SignupTestimonial = () => import("@src/components/authentication/SignupTestimonial.vue")

const LogoComponent = () => import("@src/modules/account/components/LogoComponent.vue")
</script>
<style lang="scss" scoped>
::v-deep .vti__dropdown{
  width: 64px;
}

::v-deep .vti__dropdown,
::v-deep .vti__input {
  height: 3.275rem !important
}

::v-deep .VueCarousel-pagination {
  justify-content: center;
  margin-bottom: 1rem;
}


@media screen and (max-width: 1472px) {
  .auth-buttons {
    flex-direction: column;

    span {
      width: 100%;
      justify-content: center;
    }
  }
}

.feature-img-container {
  max-width: 66rem !important;
}

.auth-form-container {
  border-radius: 0 !important;
  padding: 4rem 5.4rem !important;

  .auth-form__social-field {
    margin-top: 2.3rem;
    margin-bottom: 3rem;
    text-align: center;
  }

  .custom-border {
    border-bottom: 1px solid #cfcece92;
    border-radius: 50%;
  }
}

.left_section {
  background: linear-gradient(22deg, #C8E8FF 0%, #EEF4F7 32.18%, #EEF0F7 95.94%);
  box-shadow: 0px 0px 0px 1px #D1E3FB;

  .trusted_section {
    border-radius: 7.24px;
    border-top: 1px solid rgba(255, 255, 255, 0.30);
    background: radial-gradient(314.45% 139.15% at 3.59% 3.24%, rgba(255, 255, 255, 0.35) 0%, rgba(255, 255, 255, 0.07) 100%);
    backdrop-filter: blur(14.395000457763672px);
  }
}

</style>
