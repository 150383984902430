<template>
  <div class="h-screen overflow-x-hidden">
    <div class="flex mx-auto h-full">
      <!-- Auth form -->
      <LoginSideComponent />

      <!-- Auth slides -->
      <div class="w-full flex flex-col h-full bg-white justify-between py-[4.6rem] px-40">
        <p class="text-[#3A4557] text-right">New to Contentstudio?<router-link class="text-[#157FFF] hover:text-[#157FFF] hover:underline ml-2" :to="{ name: 'signup' }">Sign Up</router-link></p>
        <div class="flex flex-col items-center justify-center flex-grow">
          <form class="auth-form w-[29.5rem]">
            <div class="flex justify-center flex-col align-items-center auth-form__heading auth-form__heading--center">
              <h2 class="text-left text-[#2D2D2D] font-bold text-3xl">Sign in to ContentStudio</h2>
            </div>

            <div class="flex w-full justify-center pt-12 gap-3">
              <button
                  v-tooltip="'Sign in with Facebook'"
                  type="button"
                  class="w-full transition font-normal ease-in-out delay-150 text-gray-900 hover:!text-[#0068E5] bg-white border border-gray-200   font-medium rounded-[7px] text-sm p-2.5 text-center inline-flex items-center justify-center mr-2"
                  data-cy="facebook"
                  :disabled="!getSSOAuthorizationLinks.facebook"
                  @click.prevent="
                  redirectSSO(getSSOAuthorizationLinks.facebook, 'logged_in')
                "
              >
                <span class="flex align-items-center gap-2">
                  <img
                      src="@assets/img/icons/facebook.svg"
                      class="w-[22px] h-[22px]"
                      alt=""
                  />
                  Facebook
                </span>
              </button>
              <button
                  v-tooltip="'Sign in with X (formerly Twitter)'"
                  name="twitter"
                  type="button"
                  class="w-full transition ease-in-out font-normal delay-150 text-gray-900 hover:!text-[#0068E5] bg-white border border-gray-200   font-medium rounded-[7px] text-sm p-2.5 text-center inline-flex items-center justify-center mr-2"
                  data-cy="twitter"
                  :disabled="!getSSOAuthorizationLinks.twitter"
                  @click.prevent="
                  redirectSSO(getSSOAuthorizationLinks.twitter, 'logged_in')
                "
              >
                <span class="flex align-items-center gap-2">
                  <img
                      src="@assets/img/icons/twitter-x-rounded.svg"
                      class="w-[22px] h-[22px]"
                      alt="twitter"
                  />
                  Twitter
                </span>
              </button>
              <GoogleSignin v-tooltip="'Sign in with Google'"></GoogleSignin>
            </div>

            <div class="flex items-center w-full text-center leading-[0.1rem] py-4  custom-border">
              <hr class="w-full"/>
              <span class="bg-white w-16 text-[#757A8A]">or</span>
              <hr class="w-full"/>
            </div>

            <div class="flex flex-col gap-2">

              <!-- Email -->
              <CstFloatingLabelInput
                  id="email"
                  v-model="email"
                  type="email"
                  label="Email Address"
                  show-icon-left
                  value="email"
                  class="!h-[3.3rem] mb-2.5"
              >
                <template v-slot:icon>
                  <i class="icon-Email"></i>
                </template>
              </CstFloatingLabelInput>

              <!-- Password -->
              <CstFloatingLabelInput
                  id="password"
                  v-model="password"
                  type="password"
                  label="Password"
                  show-icon-left
                  value="password"
                  class="!h-[3.3rem]"
              >
                <template v-slot:icon>
                  <i class="icon-Password"></i>
                </template>
              </CstFloatingLabelInput>

              <div class="simple-form__check-field mt-2">
                <div class="field_group">
                  <div class="checkbox_container">
                    <label for="rememberCheck">
                      Remember me
                      <input
                          id="rememberCheck"
                          v-model="remember_me"
                          type="checkbox"
                          checked="checked"
                      />
                      <span class="check"></span>
                    </label>
                  </div>
                </div>

                <router-link class="text-[#157FFF] hover:text-[#157FFF] hover:underline" :to="{ name: 'forgotpassword' }"
                >Forgot Password?
                </router-link>
              </div>

            </div>
            <div class="auth-form__button-field">
              <button
                  id="sign-in"
                  name="sign-in"
                  data-cy="sign-in"
                  :disabled="loginLoader"
                  class="btn btn-studio-theme-space btn-size-large w-full !h-12 !rounded-lg"
                  @click.prevent="loginAccount"
              >
                <span class="w-full">
                  <template v-if="loginLoader">Processing</template>
                  <template v-else>Sign in</template>
                </span>
              </button>
            </div>
          </form>

          <div class="auth-form-container__footer mt-[1.6rem]">
            <p class="text-[#3A4557]"
            >Just approving content?
              <router-link class="text-[#157FFF] hover:text-[#157FFF] hover:underline" :to="{ name: 'magicLogin' }"
              >Sign in without a password
              </router-link>
            </p>
            <p
              v-if="!isWhiteLabelDomain"
              >Don’t have an account?
              <router-link :to="{ name: 'signup' }"
                ><b data-cy="sign-up">Sign Up</b></router-link
              >
            </p>
          </div>
        </div>
      </div>
      <div class="col-span-5 h-full">
        <div
          class="flex flex-col items-center justify-center text-center h-full p-9"
        >
          <div class="flex flex-col items-center max-w-3xl mb-12">
            <p class="font-bold mb-5 text-4xl">September 2024 Product Updates</p>
            <div class="bg-[#26303E] py-2 px-6 text-white rounded-lg w-max">
              <p
                class="font-bold flex items-center justify-center gap-2 text-xl"
                ><i class="far fa-crown text-google !text-sm"></i> Exciting New
                Features!</p
              >
            </div>
          </div>
          <img
            class="w-full object-contain max-h-[36rem]"
            draggable="false"
            src="@assets/img/new-features/product-updates.png"
            alt="Aug 2024 Product Updates"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { UNKNOWN_ERROR } from '@common/constants/messages'
import { authenticationTypes } from '@src/store/mutation-types'
import { mapActions, mapGetters } from 'vuex'
import { login } from '@src/modules/account/services'
const CstFloatingLabelInput = () => import('@ui/Input/CstFloatingLabelInput')
const GoogleSignin = () => import('@src/modules/account/components/GoogleSignin.vue')
import { workspaceTypes } from '@modules/setting/store/states/mutation-types'
import useWhiteLabelApplication from "@modules/setting/composables/whitelabel/useWhiteLabelApplication";
const LoginSideComponent = () => import("@src/components/authentication/LoginSideComponent.vue");

export default {
  name: 'SignIn',
  components: {
    LoginSideComponent,
    CstFloatingLabelInput,
    GoogleSignin,
  },
  setup() {
    const {isWhiteLabelDomain} = useWhiteLabelApplication()

    return {
      isWhiteLabelDomain,
    }
  },
  data() {
    return {
      email: '',
      password: '',
      loginLoader: false,
      remember_me: false,
    }
  },
  computed: {
    ...mapGetters(['getSSOAuthorizationLinks', 'getWorkspaces']),
  },
  created() {
    console.log('METHOD::created::SignIn')
    this.fetchSSOLinks()
  },
  methods: {
    ...mapActions(['fetchDiscover', 'fetchSocialAccounts']),

    async loginAccount() {
      console.debug('Method::loginAccount')

      /**
       * If query params contain Frill.co SSO
       * Authenticate user and redirect to frill redirect URI
       */
      const postData = this.getFrillParams()

      if (!this.email) {
        this.alertMessage('Please enter an email address', 'error')
        return
      }
      if (!this.password) {
        this.alertMessage('Please enter a password', 'error')
        return
      }

      this.loginLoader = true

      try {
        const response = await login({
          username: this.email,
          password: this.password,
          remember_me: this.remember_me,
          ...postData,
        })

        // reset active workspace on login
        this.$store.commit(workspaceTypes.SET_ACTIVE_WORKSPACE, null)

        // check if user has enabled 2FA
        if (response['2fa_enabled']) {
          await this.$router.push({
            name: 'twoFactor',
            params: {
              token: response.user_info,
            },
            query: {
              ...this.$route.query,
            },
          })
          return
        }

        // set the cookies
        this.$store.commit(authenticationTypes.SET_JWT_TOKEN, response.token)
        this.$store.commit(
            authenticationTypes.SET_LOGGED_USER,
            response.logged_user
        )
        this.$store.commit(authenticationTypes.SET_IS_AUTHENTICATED, true)

        // custom event for user maven
        this.trackUserMaven('logged_in')

        // If login is request from SSO - Frill.co, redirect user
        if (response.redirect) {
          window.location.href = response.redirect
        }

        if (!this.isUserStateActive(response.user)) {
          return
        }

        // check for redirected url
        if (
            this.$route.query.redirected_url &&
            this.$route.query.redirected_url !== '/logout' &&
            this.$route.query.redirected_url !== '/login'
        ) {
          window.location.href = this.$route.query.redirected_url
          return
        }

        if (response.slug) {
          await this.$router.push({
            name: response?.user?.preferences?.default_landing_page || 'dashboard',
            params: { workspace: response.slug },
          })
          return
        }

        await this.fetchProfile()
        this.isEmailVerified()
      } catch (error) {
        this.alertMessage(error.message || UNKNOWN_ERROR, 'error')
      }

      this.loginLoader = false
    },
  },
}
</script>
