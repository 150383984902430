<script setup>
import { ref } from 'vue'
import { Carousel, Slide } from '@jambonn/vue-concise-carousel'

const testimonials = ref([
  {
    content: "ContentStudio.io is a powerful content curation tool that ensures you can become part of, influence, and even generate thought leadership around a topic that is of interest to you or your business. The high level of customization helps you publish the right message to the right social network at the right time for maximum engagement.",
    name: "Neal Schaffer",
    position: "Author, Global Speaker, Marketing Influencer",
    image: require("@assets/img/onboarding/neal-schaffer.png") // placeholder image
  },
  {
    content: "Content allows us to have a variable team where we can have all players on one platform. It aggregates all elements of social media management into one app; social media content scheduling and publishing, customer service, and analytics. It is quite astounding how much value this platform packs. ContentStudio really rocks it for us.",
    name: "Anthony",
    position: "CEO at Lake Placid Film Festival",
    image: require("@assets/img/onboarding/anthony.png") // placeholder image
  },
  {
    content: "I've been a big fan of Content Studio from the first time I tried it. Not only is it super easy to find amazing relevant content to share with your audience and grow your influence, but I absolutely love how it helps you create better-optimised content for social media and your blog, easier than ever.",
    name: "Lilach Bullock",
    position: "Content Creation Expert, Speaker, Author, Trainer & Consultant",
    image: require("@assets/img/onboarding/lilach.png") // placeholder image
  },
  {
    content: "ContentStudio is a time and lifesaver. We love the automation recipes, especially \"articles to social media.\" You put your social on autopilot, sit back and reap the rewards.",
    name: "Agata Pawlak",
    position: "Founder at CruzCreative.Agency",
    image: require("@assets/img/onboarding/agata.png") // placeholder image
  },
  {
    content: "ContentStudio helps me and my team to instantly approve posts and gives me control over the content that goes on my social channels.",
    name: "James Pemberton",
    position: "Proactive Healthcare Recruiters",
    image: require("@assets/img/onboarding/james.png") // placeholder image
  },
])
</script>

<template>
  <Carousel
      pagination-color="#D2D5DF"
      pagination-active-color="#2973E5"
      :per-page="1"
      :autoplay="true"
      :autoplay-timeout="5000"
      :loop="true"
      class="h-[23.5rem] w-full dashboardCarousel__carousel"
  >
    <Slide v-for="(testimonial, index) in testimonials" :key="index">
      <div class="w-full h-[20rem] p-1 flex flex-col justify-between">
        <div class="flex flex-grow">
          <p class="pt-6 text-lg text-[#0D2859]">"{{ testimonial.content }}"</p>
        </div>
        <div class="w-full flex flex-nowrap gap-x-4 items-center">
          <img :src="testimonial.image"
               class="w-14 h-14 rounded-full"
               alt="ContentStudio"
          />
          <div>
            <h2 class="text-lg font-semibold text-[#0D2859]">{{ testimonial.name }}</h2>
            <p class="text-[#0D2859]">{{ testimonial.position }}</p>
          </div>
        </div>
      </div>
    </Slide>
  </Carousel>
</template>
