<script setup>
import { ref } from 'vue'
import useDomainSettings from '@modules/setting/composables/whitelabel/useDomainSettings'
import CstButton from '@ui/Button/CstButton.vue'

const emit = defineEmits(['next', 'prev'])

const {
  whiteLabelDomain,
  dnsType,
  domainHost,
  domainValue,
  isLoading,
  shouldDisableNext,
  isDomainVerified,
  validateDomain,
  handleSubmit,
} = useDomainSettings()

const showHostCopied = ref(false)
const showValueCopied = ref(false)

const onSubmit = async () => {
  if (await handleSubmit()) {
    emit('next')
  }
}

const copyToClipboard = (type = '') => {
  if (type === 'host') {
    navigator.clipboard.writeText(domainHost.value?.value).then(() => {
      showHostCopied.value = true
      setTimeout(() => {
        showHostCopied.value = false
      }, 2000)
    })
  } else if (type === 'value') {
    navigator.clipboard.writeText(domainValue.value?.value).then(() => {
      showValueCopied.value = true
      setTimeout(() => {
        showValueCopied.value = false
      }, 2000)
    })
  }
}
</script>

<template>
  <div class="bg-orange-50 border-l-4 border-orange-400 p-4 mb-6 rounded-lg">
    <div class="flex">
      <div class="flex-shrink-0">
        <svg
          class="h-5 w-5"
          viewBox="0 0 384 512"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M297.2 248.9C311.6 228.3 320 203.2 320 176c0-70.7-57.3-128-128-128S64 105.3 64 176c0 27.2 8.4 52.3 22.8 72.9c3.7 5.3 8.1 11.3 12.8 17.7l0 0c12.9 17.7 28.3 38.9 39.8 59.8c10.4 19 15.7 38.8 18.3 57.5H109c-2.2-12-5.9-23.7-11.8-34.5c-9.9-18-22.2-34.9-34.5-51.8l0 0 0 0c-5.2-7.1-10.4-14.2-15.4-21.4C27.6 247.9 16 213.3 16 176C16 78.8 94.8 0 192 0s176 78.8 176 176c0 37.3-11.6 71.9-31.4 100.3c-5 7.2-10.2 14.3-15.4 21.4l0 0 0 0c-12.3 16.8-24.6 33.7-34.5 51.8c-5.9 10.8-9.6 22.5-11.8 34.5H226.4c2.6-18.7 7.9-38.6 18.3-57.5c11.5-20.9 26.9-42.1 39.8-59.8l0 0 0 0 0 0c4.7-6.4 9-12.4 12.7-17.7zM192 128c-26.5 0-48 21.5-48 48c0 8.8-7.2 16-16 16s-16-7.2-16-16c0-44.2 35.8-80 80-80c8.8 0 16 7.2 16 16s-7.2 16-16 16zm0 384c-44.2 0-80-35.8-80-80V416H272v16c0 44.2-35.8 80-80 80z"
            fill="#df7620"
          />
        </svg>
      </div>
      <div class="ml-3">
        <p class="text-sm text-orange-700">
          Changes to DNS configuration may take up to
          <span class="font-semibold">24 hours to propagate</span>
          fully.
        </p>
        <p class="mt-2 text-sm text-orange-700">
          If using Cloudflare, ensure the Cloudflare Proxy option is disabled.
          For more details, refer to
          <a
            class="font-medium underline text-primary-cs hover:text-primary-cs-500"
            href="#"
            >this article</a
          >.
        </p>
      </div>
    </div>
  </div>
  <form @submit.prevent="onSubmit">
    <div class="grid grid-cols-2 gap-6 mb-6">
      <div>
        <label
          class="block mb-2 font-medium text-gray-900"
          for="whiteLabelDomain"
        >
          White Label Domain<span class="text-red-600">*</span>
          <i
            v-tooltip="
              'Use your custom domain (e.g., app.yourbrand.com) to access the content studio platform instead of app.contentstudio.io'
            "
            class="far fa-question-circle p-0 cursor-pointer text-primary-cs ml-2"
          ></i>
        </label>
        <div class="flex relative">
          <input
            id="whiteLabelDomain"
            v-model="whiteLabelDomain.value"
            placeholder="e.g., app.yourbrand.com"
            :class="{ '!border-red-300': whiteLabelDomain.error }"
            :required="whiteLabelDomain.isRequired"
            class="flex-grow border border-gray-300 rounded-lg px-3 py-2.5 focus:outline-none focus:ring-2 focus:ring-blue-500 color-border cst-editor"
            type="text"
          />
          <img
            v-if="isDomainVerified"
            v-tooltip="`Verified Domain`"
            alt=""
            class="absolute right-3 top-3 w-5 h-5"
            src="@assets/img/icons/statuses/published_new.svg"
          />
        </div>
        <p v-if="whiteLabelDomain.error" class="mt-1 text-sm text-red-600">{{
          whiteLabelDomain.error
        }}</p>
      </div>

      <div>
        <label class="block mb-2 font-medium text-gray-700" for="dnsType"
          >Type</label
        >
        <input
          id="dnsType"
          v-model="dnsType.value"
          class="w-full border border-gray-300 rounded px-3 py-2.5 focus:outline-none focus:ring-2 focus:ring-blue-500 color-border cst-editor"
          readonly
          type="text"
        />
      </div>

      <div>
        <label class="block mb-2 font-medium text-gray-700" for="domainHost"
          >Host</label
        >
        <div class="flex items-center relative">
          <input
            id="domainHost"
            v-model="domainHost.value"
            class="flex-grow border border-gray-300 rounded px-3 py-2.5 focus:outline-none focus:ring-2 focus:ring-blue-500 color-border cst-editor"
            readonly
            type="text"
          />
          <div class="absolute right-3 top-3">
            <p v-if="showHostCopied" class="text-xs text-green-600">Copied!</p>
            <img
              v-else
              v-tooltip="'Copy'"
              alt=""
              class="w-5 h-5 cursor-pointer"
              src="@src/assets/img/integration/copy_url.svg"
              @click="copyToClipboard('host')"
            />
          </div>
        </div>
      </div>

      <div>
        <label class="block mb-2 font-medium text-gray-700" for="domainValue"
          >Value</label
        >
        <div class="flex items-center relative">
          <input
            id="domainValue"
            v-model="domainValue.value"
            class="flex-grow border border-gray-300 rounded px-3 py-2.5 focus:outline-none focus:ring-2 focus:ring-blue-500 color-border cst-editor"
            readonly
            type="text"
          />
          <div class="absolute right-3 top-3">
            <p v-if="showValueCopied" class="text-xs text-green-600">Copied!</p>
            <img
              v-else
              v-tooltip="'Copy'"
              alt=""
              class="w-5 h-5 cursor-pointer"
              src="@src/assets/img/integration/copy_url.svg"
              @click="copyToClipboard('value')"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="flex justify-between">
      <CstButton
        class="!border-gray-cs-500 !text-gray-cs-600"
        size="small"
        variant="outlined"
        @click="emit('prev')"
      >
        Back
      </CstButton>
      <div class="flex gap-2">
        <CstButton
          :disabled="shouldDisableNext"
          variant="outlined"
          @click="validateDomain"
        >
          Verify and Save Domain
        </CstButton>
        <CstButton
          :disabled="shouldDisableNext"
          :loading="isLoading"
          type="submit"
        >
          Next
        </CstButton>
      </div>
    </div>
  </form>
</template>
